import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { RxAvatar } from "react-icons/rx";

const ShopCreate = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [avatar, setAvatar] = useState("");
  const [tempavatar, settempAvatar] = useState("");
  const [processing, setProcessing] = useState(false);
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
  
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('password', password);
      formData.append('avatar', avatar); // Assuming avatar is the file object
      formData.append('zipCode', zipCode);
      formData.append('address', address);
      formData.append('phoneNumber', phoneNumber);
  
      const response = await axios.post(`${server}/shop/create-shop`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important to set the content type for file uploads
        },
      });
  
      toast.success(response.data.message);
      setName("");
      setEmail("");
      setPassword("");
      setAvatar("");
      setZipCode("");
      setAddress("");
      setPhoneNumber("");
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    } finally {
      setProcessing(false);
    }
  };
  

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setAvatar(file);
    const url = URL.createObjectURL(file);
    settempAvatar(url);

  };
  

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-1 sm:px-6 lg:px-8">
      <div className="flex justify-center mb-5">
      <Link to="/user-login"><button
                      className="mt-4 text-white font-semibold rounded bg-transparent border px-10 py-4 hover:text-white transition-colors duration-300"
                      style={{
                        borderRadius: "8px",
                        backgroundImage:
                          "linear-gradient(to right, #0000FF, #FF0000)",
                        backgroundSize: "200% auto",
                        transition: "background-position 0.5s",
                        fontSize: "1rem", // Adjust the font size as needed
                        textAlign: "center", // Center the text horizontally
                        width: "fit-content", // Make the button width fit its content
                        fontWeight: "700"
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundPosition = "right")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundPosition = "left")
                      }
                    >
                    Return to Sign In
                    </button></Link>  
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900">
          Register New User
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-[35rem]">
        <div className="bg-white py-8 mb-10 px-4 shadow sm:rounded-lg sm:px-10 bg-slate-300">
        <form className="space-y-6" onSubmit={handleSubmit} encType="multipart/form-data">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Company Full Name
              </label>
              <div className="mt-1">
                <input
                  type="name"
                  name="name"
                  placeholder="eg: Aarya Computer Trading L.L.C"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                TRN (VAT Registration Number) - If any
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  name="phone-number"
                  placeholder="eg: XXXXXXXXXXXXX"
                  required
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder="eg: name@example.com"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Company Full Address
              </label>
              <div className="mt-1">
                <input
                  type="address"
                  name="address"
                  placeholder="eg: Al Fahidi, Bur Dubai, Dubai, UAE"
                  required
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Zip Code
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  name="zipcode"
                  required
                  value={zipCode}
                  placeholder="eg: 00000"
                  onChange={(e) => setZipCode(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  placeholder="e.g: Abc123$#"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="avatar"
                className="block text-sm font-medium text-gray-700"
              ></label>
              <div className="mt-2 flex items-center">
                <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
                {tempavatar ? (
                    <img
                      src={tempavatar}
                      alt="avatar"
                      className="h-full w-full object-cover rounded-full"
                    />
                  ) : (
                    <RxAvatar className="h-8 w-8" />
                  )}
                </span>
                <label
                  htmlFor="file-input"
                  className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  <span>Upload a Logo (If Any)</span>
                  <input
                    type="file"
                    name="avatar"
                    id="file-input"
                    onChange={handleFileInputChange}
                    className="sr-only"
                    required
                  />
                </label>
              </div>
            </div>
                
            <div>
            <button
                type="submit"
                disabled={processing}
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                {processing ? "Processing..." : "Submit"}
              </button>
            </div>
            <div className={`${styles.noramlFlex} w-full`}>
              <h4>Already have an account?</h4>
              <Link to="/user-login" className="text-blue-800 pl-2">
                Sign in
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopCreate;
