import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProduct } from "../../redux/actions/product";
import { toast } from "react-toastify";
import { getAllProductsShop } from "../../redux/actions/product";
import { getAllEventsShop } from "../../redux/actions/event";

const ItemDetailsForm = ({ itemsList, setItemsList }) => {

  const handleAddItem = () => {
    setItemsList((prevItems) => [
      ...prevItems,
      {
        description: "",
        stock: "",
        unit: "",
        unitprice: "",
        productamount: "",
        discount: "",
        discountPrice: "",
        vatrate: "",
        amtaftervat: "",
      },
    ]);
  };

  const handleItemChange = (index, fieldName, value) => {
    setItemsList((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index][fieldName] = value;
      return updatedItems;
    });
  };

  const handleRemoveItem = (index) => {
    setItemsList((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });
  };

  useEffect(() => {
    itemsList.forEach((item, index) => {
      const stockValue = parseFloat(item.stock);
      const unitpriceValue = parseFloat(item.unitprice);
        const amountValue = stockValue * unitpriceValue;
          setItemsList((prevItems) => {
            const updatedItems = [...prevItems];
            if (updatedItems[index]) {
              updatedItems[index].productamount = amountValue.toFixed(2);
            }
            return updatedItems;
          });
    });
  }, [itemsList]);

  useEffect(() => {
    itemsList.forEach((item, index) => {
      const totalamount = parseFloat(item.productamount);
      const discountrate = parseFloat(item.discount);
      const discountedAmount = totalamount - (totalamount * discountrate / 100);

          setItemsList((prevItems) => {
            const updatedItems = [...prevItems];
            if (updatedItems[index]) {
              updatedItems[index].discountPrice = discountedAmount.toFixed(2);
            }
            return updatedItems;
          });
    });
  }, [itemsList]);

  useEffect(() => {
    itemsList.forEach((item, index) => {
      const distotalamount = parseFloat(item.discountPrice);
      const VatRate = parseFloat(item.vatrate);
      const AmtAVAT = distotalamount + (distotalamount * VatRate / 100);

          setItemsList((prevItems) => {
            const updatedItems = [...prevItems];
            if (updatedItems[index]) {
              updatedItems[index].amtaftervat = AmtAVAT.toFixed(2);
            }
            return updatedItems;
          });
    });
  }, [itemsList]);
  
  return (
    <>
      {itemsList.map((item, index) => (
        <div key={index} className="">
          <div className="border-solid border-2 border-black p-3">
        <div>
          <label className="pb-2">
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="4"
            type="text"
            name="description"
            value={item.description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-xs"
            onChange={(e) => handleItemChange(index, "description", e.target.value)}
            placeholder="Enter your product/services description..."
          ></textarea>
        </div>
        <br />
        <div>
          <label className="pb-2">
          Quantity <span className="text-red-500">*</span>
          </label>
          <input
          required
            type="number"
            name="quantity"
            value={item.stock}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => handleItemChange(index, "stock", e.target.value)}
            placeholder="Enter your quantity or 1"
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Unit
          </label>
          <input
            type="text"
            name="unit"
            value={item.unit}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => handleItemChange(index, "unit", e.target.value)}
            placeholder="Enter your product/services description..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Unit Price <span className="text-red-500">*</span>
          </label>
          <input
          required
            type="number"
            name="Unitprice"
            value={item.unitprice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => handleItemChange(index, "unitprice", e.target.value)}
            placeholder="Enter per unit price..."
          /> 
        </div>

        <br />
        <div>
          <label className="pb-2">Amount</label>
          <input
            name="amount"
            value={item.productamount} 
            onChange={(e) => handleItemChange(index, "productamount", e.target.value)}
            readOnly // Make the input read-only
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>
        <br />
        <div>
          <label className="pb-2">Discount(%) </label> <span className="text-red-500">*</span>
          <input
          required
            type="number"
            name="discount"
            value={item.discount}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => handleItemChange(index, "discount", e.target.value)}
            placeholder="Enter discount or enter 0"
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Amount (After Discount)
          </label>
          <input
            type="number"
            name="price"
            value={item.discountPrice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => handleItemChange(index, "discount", e.target.value)}
            readOnly // Make the input read-only
          />
        </div>
        <br />
        <div>
          <label className="pb-2">VAT(%) </label> <span className="text-red-500">*</span>
          <input
          required
            type="number"
            name="vat"
            value={item.vatrate}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => handleItemChange(index, "vatrate", e.target.value)}
            placeholder="Enter VAT Rate or 0"
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Amount (After VAT)
          </label>
          <input
            type="number"
            name="price"
            value={item.amtaftervat}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => handleItemChange(index, "amtaftervat", e.target.value)}
            readOnly // Make the input read-only
          />
        </div>    
      </div>
          {/* Include remove button for each item */}
          <button
            type="button"
            onClick={() => handleRemoveItem(index)}
            className="text-red-600 py-2 font-[600] p-2 my-1 text-[20px]"
          >
            Remove Above Item
          </button>
        </div>
      ))}
      <button className="text-green-600 font-[600] p-2 text-[20px]" type="button"  onClick={handleAddItem}>
        Add Item
      </button>
    </>
  );
};
export default ItemDetailsForm;
