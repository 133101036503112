import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Loader from "../components/Layout/Loader";

const SellerLicenceProtected = ({ children }) => {
  const { isLoading, isSeller } = useSelector((state) => state.seller);
  const { seller } = useSelector((state) => state.seller);
  if (isLoading === true) {
    return <Loader />;
  } 
  else if (seller && seller.email !== "info@aaryacomputer.com") {
    return <Navigate to={`/license-expired`} replace />;
  } else {
    if (!isSeller) {
      return <Navigate to={`/user-login`} replace />;
    }
    return children;
  }
};

export default SellerLicenceProtected;
