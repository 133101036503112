import React from "react";
import { AiOutlineGift } from "react-icons/ai";
import { MdOutlineLocalOffer } from "react-icons/md";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { backend_url} from "../../../../src/server";
import { BiMessageSquareDetail } from "react-icons/bi";
import logo from "../../../Assests/logo.png";
const DashboardHeader = () => {
  const { seller } = useSelector((state) => state.seller);
  return (
    <div className="w-full h-[60px] bg-slate-300 shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div className="font-bold text-[16px] sm:text-[20px]">
        <Link to="/dashboard">
        <h1>{seller.name} - Sales Invoice</h1>
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-2 sm:mr-5">
          
          <Link to="/dashboard-invoices" className="800px:block hidden">
            <FiShoppingBag
              color="#555"
              size={30}
              className="mx-5 cursor-pointer"
            />
          </Link>
          <Link className="ml:2 sm:ml-5" to={`/settings`}>
            <img
              src={`${backend_url}${seller.avatar?.url}`}
              alt=""
              className="w-[auto] max-h-[50px] py-1 rounded-sm object-cover"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
