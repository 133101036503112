import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ShopCreate from "../components/Shop/ShopCreate";
import Footer from "../components/Layout/Footer";
import Whatsapp from "../components/Whatsapp/whatsapp";


const ShopCreatePage = () => {
  const navigate = useNavigate();
  const { isSeller, seller } = useSelector((state) => state.seller);

  useEffect(() => {
    document.title = 'Sign Up - Conscious Invoice';
  }, []);

  useEffect(() => {
    if (isSeller === true && seller) { // Check if seller object exists
      navigate('/settings');
    }
  }, [isSeller, seller, navigate]); // Add dependencies to useEffect

  return (
    <div>
      <ShopCreate />
      <Whatsapp />
      <Footer />
    </div>
  );
}

export default ShopCreatePage;
