import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { updateProduct } from "../../redux/actions/product";
import { toast } from "react-toastify";
import { AiOutlinePlusCircle } from "react-icons/ai"; // Import AiOutlinePlusCircle
import { categoriesData } from "../../static/data";
import axios from "axios";
import { server } from "../../server";
import ItemDetailsForm from "./ItemDetailsForm";
import { getAllProductsShop } from "../../redux/actions/product";
import { getAllEventsShop } from "../../redux/actions/event";

const UpdateProduct = () => {
  const { id } = useParams();
  const { seller } = useSelector((state) => state.seller);
  const { products, success, error } = useSelector((state) => {
    console.log(state);
    return state.products;
  });
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    dispatch(getAllProductsShop(seller._id));
    dispatch(getAllEventsShop(seller._id));
  }, [dispatch, seller._id]);


  // Initialize state values based on the data you want to bind
  const [images, setImages] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedParty, setSelectedParty] = useState(null);
  const [eventDetails, setEventDetails] = useState(null);
  const { events, isLoading: eventsLoading } = useSelector((state) => state.events);
  const [totalProductAmount, setTotalProductAmount] = useState(0);
  const [totalDiscountedAmount, setTotalDiscountedAmount] = useState(0);
  const [totalVATAmount, setTotalVATAmount] = useState(0);
  const [totalInvoice, setTotalInvoice] = useState(0);
  const [TotalafterlDiscountedAmount, setTotalafterlDiscountedAmount] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState(0); // State to track invoice number
  const { allProducts, isLoading: productsLoading } = useSelector((state) => state.products);
  const [updateSuccess, setUpdateSuccess] = useState(false); // State variable to track update success
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");
  const [term, setTerm] = useState("");

  const getProduct = async () => {
    const response = await axios.get(`${server}/product/get-product/${id}`);
    const data = response.data;
    console.log("Dataaaaaa", data);
    if (response) {
      setProduct(data.products);
    } else {
      console.error("Error");
    }
  };
  
  useEffect(() => {
    console.log("Product Fetched", id);
    getProduct();
  }, [id]); // Include id as a dependency if it's used inside getProduct
  
  useEffect(() => {
    if (product && product.itemsList) {
      if(product.shopId === seller._id){
      setItemsList(product.itemsList);
    }
    }
  }, [product]);

  useEffect(() => {
    if (!productsLoading && !eventsLoading && allProducts && events && id) {
      // Filter products based on seller._id and shopId
      const clonedProducts = allProducts.slice();
      const filteredProducts = clonedProducts.filter(
        (item) => item.shopId === seller._id
      );
      const data = filteredProducts.find((item) => item._id === id);
      setData(data);
      setInvoiceNumber(data?.invoiceNumber);
      setSelectedParty(data?.selectedParty);
      setStartDate(data?.startDate);
      setEndDate(data?.endDate);
      setTerm(data?.term);
      setLoading(false); // Data loaded, update loading state
    }
  }, [productsLoading, eventsLoading, allProducts, events, id, seller]);

  useEffect(() => {
    if (selectedParty) {
      const selectedEvent = events.find(event => event._id === selectedParty);
      setEventDetails(selectedEvent);
    } else {
      setEventDetails(null);
    }
  }, [selectedParty, events]);
  
  useEffect(() => {
    // Calculate total product amount
    const sumProductAmount = itemsList.reduce((total, item) => total + parseFloat(item.productamount), 0);
    setTotalProductAmount(sumProductAmount.toFixed(2));
  
    // Calculate total discounted amount
    const sumDiscountedAmount = itemsList.reduce((total, item) => total + parseFloat(item.productamount-item.discountPrice), 0);
    setTotalDiscountedAmount(sumDiscountedAmount.toFixed(2));
  
     // Calculate total amount after discount
     const afterDiscountedAmount = itemsList.reduce((total, item) => total + parseFloat(item.discountPrice), 0);
     setTotalafterlDiscountedAmount(afterDiscountedAmount.toFixed(2));
   
    // Calculate total VAT amount
    const sumVATAmount = itemsList.reduce((total, item) => total + parseFloat(item.amtaftervat-item.discountPrice), 0);
    setTotalVATAmount(sumVATAmount.toFixed(2));
  
    // Calculate total invoice
    const sumTotalInvoice = sumProductAmount - sumDiscountedAmount + sumVATAmount;
    setTotalInvoice(sumTotalInvoice.toFixed(2));
  }, [itemsList]);
  
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      setUpdateSuccess(true); // Set update success to true
    }
  }, [error, success]);

  

  // Function to handle image change
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const totalImages = images.length + files.length;

    if (totalImages <= 3) {
      files.forEach((file) => {
        const reader = new FileReader();

        reader.onload = () => {
          if (reader.readyState === 2) {
            const image = new Image();
            image.src = reader.result;

            image.onload = () => {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");

              const MAX_HEIGHT = 542; // Maximum height

              let width = image.width;
              let height = image.height;

              // Calculate the width while maintaining aspect ratio
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }

              canvas.width = width;
              canvas.height = height;

              ctx.drawImage(image, 0, 0, width, height);

              const resizedDataUrl = canvas.toDataURL("image/jpeg", 0.7); // Adjust the quality (0.7 is 70% quality)

              setImages((old) => [...old, resizedDataUrl]);
            };
          }
        };
        reader.readAsDataURL(file);
      });
    } else {
      toast.error("You can only upload up to three images.");
    }
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Show processing message
    setIsUpdating(true);

     // Check if selectedParty is blank or "Choose a selected Party"
  if (!selectedParty || !eventDetails) {
    toast.error("Please select party");
    setProcessing(false);
    return; // Exit the function early if party is not selected
  }

   
    try {
      await dispatch(
        updateProduct(id, {
          // Pass the id along with other product details
        startDate,
        endDate,
        selectedParty,
        totalProductAmount,
        totalDiscountedAmount,
        TotalafterlDiscountedAmount,
        totalVATAmount,
        totalInvoice,
        itemsList,
        invoiceNumber,
        images,
        term,
        })
      );

      // Show success message after successful update
      setUpdateSuccess(true); 
      navigate("/dashboard-invoices");
      window.location.reload();
      toast.success("Invoice updated successfully!");
    } catch (error) {
      // Show error message if update fails
      toast.error("Failed to update product. Please try again later.");
    } finally {
      // Reset the updating state after navigating or updating fails
      setIsUpdating(false);
    }
  };
 
  
  return (
    <div className="w-[90%] 800px:w-[60%] bg-white shadow h-full rounded-[4px] p-5 800px:p-8 overflow-y-scroll">
      <div className="flex pb-3 justify-between"><h5 className="text-[22px] font-Poppins text-center">Update Invoice</h5><p className="text-[18px]">#{seller.description}00000{data?.invoiceNumber}</p></div>
      {updateSuccess && <p>Your product has been updated.</p>}
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Invoice Date <span className="text-red-500">*</span>
          </label>
          <input
          required
            type="date"
            name="invoice-date"
            id="start-date"
            value={startDate}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Enter your event product stock..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Due Date <span className="text-red-500">*</span>
          </label>
          <input
            required
            type="date"
            name="due-date"
            id="end-date"
            value={endDate}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="Enter your event product stock..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Name of Party <span className="text-red-500">*</span>
          </label>
          <select
                    required
                      className="w-full mt-2 border h-[35px] rounded-[5px]"
                      value={selectedParty}
                      onChange={(e) => setSelectedParty(e.target.value)}
                    >
                      <option value="Choose your selected Party">
                        Choose a selected Party
                      </option>
                      {events &&
                        events.map((i) => (
                          <option value={i._id} key={i._id}>
                            {i.name}
                          </option>
                        ))}
                    </select>
                    {eventDetails && (
        <div className="pt-2">
          <p>Address: {eventDetails.description}</p>
          <p>TRN: {eventDetails.category}</p>
          <p>Contact Person: {eventDetails.tags}</p>
          <p>Contact No: {eventDetails.originalPrice}</p>
          {/* Add other details here */}
        </div>
      )}
        </div>
        <br />
        <ItemDetailsForm itemsList={itemsList} setItemsList={setItemsList} />
        <br />
      <br />
        <div>
                <h4>Total Items Price: {totalProductAmount}</h4>
                <h4>Total Discounted Amount: {totalDiscountedAmount}</h4>
                <h4>Taxable Amount: {TotalafterlDiscountedAmount}</h4>
                <h4>VAT Amount: {totalVATAmount}</h4>
                <h4>Total Invoice: {totalInvoice}</h4>
              </div>
              <div>
          <br />
          <div>
          <label className="pb-2">
            Terms and Conditions
          </label>
          <textarea
            cols="30"
            rows="4"
            type="text"
            name="term"
            value={term}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-xs"
            onChange={(e) => setTerm(e.target.value)}
            placeholder="Enter bill teams or notes if any..."
          ></textarea>
        </div>
          {/* <br />
          <label className="pb-2">
            Upload supporting(If need)
          </label>
          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />
          <div className="w-full flex items-center flex-wrap">
            <label htmlFor="upload">
              <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
            </label>
            {images &&
              images.map((img, index) => (
                <img
                  src={img}
                  key={index}
                  alt=""
                  className="h-[120px] w-[120px] object-cover m-2"
                />
              ))}
          </div> */}
          <br />
          <div>
            <input
              type="submit"
              value="Update"
              className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateProduct;
