import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  ActivationPage,
  HomePage,
  ShopCreatePage,
  SellerActivationPage,
  ShopLoginPage,
} from "./routes/Routes.js";
import {
  ShopDashboardPage,
  ShopCreateProduct,
  ShopAllProducts,
  ShopCreateEvents,
  ShopAllEvents,
  ShopOrderDetails,
  ShopSettingsPage,
  ShopUpdateProduct,
} from "./routes/ShopRoutes";
import PasswordReset from "./components/Login/Password.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Store from "./redux/store";
import { loadSeller, loadUser } from "./redux/actions/user";
import SellerProtectedRoute from "./routes/SellerProtectedRoute";
import { getAllProducts } from "./redux/actions/product";
import { getAllEvents } from "./redux/actions/event";
import axios from "axios";
import { server } from "./server";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ScrollToTop from "./components/scroll.js";

import { useTranslation } from "react-i18next";
import LanguageSelector from "./components/Language-selector.js";
import ResetPassword from "./components/Login/Password.jsx";
import Licenseexpire from "./pages/Licenseexpire.jsx";
import LicenceExpireProtect from "./routes/LicenceExpireProtect.js";
import ShopUpdateCustomer from "./pages/Shop/ShopUpdateCustomer.jsx";
import Licences from "./pages/Licences.jsx";
import SellerLicenceProtected from "./routes/SellerLicenceProtected.js";
import { getAllLicencedetail } from "./redux/actions/licence.js";

const App = () => {

  useEffect(() => {
    Store.dispatch(loadUser());
    Store.dispatch(loadSeller());
    Store.dispatch(getAllProducts());
    Store.dispatch(getAllLicencedetail());
    Store.dispatch(getAllEvents());
  }, []);

  return (
    <BrowserRouter>
    <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/forgot-password" element={<PasswordReset />} />
        <Route
          path="/activation/:activation_token"
          element={<ActivationPage />}
        />
        <Route
          path="/seller/activation/:activation_token"
          element={<SellerActivationPage />}
        />
        <Route
          path="/license-expired"
          element={
          <LicenceExpireProtect>
          <Licenseexpire />
          </LicenceExpireProtect>
        }
        />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        {/* shop Routes */}
        <Route path="/create-user" element={<ShopCreatePage />} />
        <Route path="/user-login" element={<ShopLoginPage />} />
        <Route
          path="/settings"
          element={
            <SellerProtectedRoute>
              <ShopSettingsPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <SellerProtectedRoute>
              <ShopDashboardPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-invoice"
          element={
            <SellerProtectedRoute>
              <ShopCreateProduct />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/update-invoice/:id" // Dynamic route with :id parameter
          element={
            <SellerProtectedRoute>
              <ShopUpdateProduct />{" "}
              {/* Render the UpdateProductPage component */}
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/update-customers/:id" // Dynamic route with :id parameter
          element={
            <SellerProtectedRoute>
              <ShopUpdateCustomer />{" "}
              {/* Render the UpdateProductPage component */}
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/licence"
          element={
            <SellerLicenceProtected>
              <Licences />{" "}
              </SellerLicenceProtected>
          }
        />
        <Route
          path="/invoice/:id"
          element={
            <SellerProtectedRoute>
              <ShopOrderDetails />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-invoices"
          element={
            <SellerProtectedRoute>
              <ShopAllProducts />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-customers"
          element={
            <SellerProtectedRoute>
              <ShopCreateEvents />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-customers"
          element={
            <SellerProtectedRoute>
              <ShopAllEvents />
            </SellerProtectedRoute>
          }
        />
      </Routes>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
};

export default App;
