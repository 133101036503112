import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Loader from "../components/Layout/Loader";
import axios from "axios";

const SellerProtectedRoute = ({ children }) => {
  const { isLoading, isSeller } = useSelector((state) => state.seller);
  const { seller } = useSelector((state) => state.seller);
  const [serverDate, setServerDate] = useState(null);

  useEffect(() => {
    axios.get("https://invoice.consciousgrp.com:8002/api/server-date")
      .then((response) => {
        setServerDate(new Date(response.data.serverDate));
      })
      .catch((error) => {
        console.error("Error fetching server date:", error);
      });
  }, []);
  
  const currentDate = new Date();
  if (isLoading === true) {
    return <Loader />;
    } else if (!isSeller) {
      return <Navigate to={`/user-login`} replace />;
    } else if (seller.licenseNumber === null){
      return <Navigate to={`/license-expired`} replace />;
    }else if (serverDate && new Date(seller.licexpireDate) < serverDate) {
      return <Navigate to={`/license-expired`} replace />;
    } else {
    return children;
  }
};

export default SellerProtectedRoute;