import React, { useState } from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  footercompanyLinks,
  footerProductLinks,
  footerSupportLinks,
} from "../../static/data";
import logo from "../../Assests/logo.png";
import axios from "axios";
import { server } from "../../server";
import cn from "../../Assests/cn.png";
import aarya from "../../Assests/logo icon.png";
const Footer = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const [processing, setProcessing] = useState(false);
  const handleClick = () => {
    window.scrollTo(0, 0); // Scroll to the top
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false); // Reset success state

    if (!email) {
      setError("Email address is required.");
      return;
    }

    setProcessing(true); // Set processing to true when form is submitted

    try {
      const response = await axios.post(`${server}/subscription/subscribe`, {
        email,
      });

      if (response.status === 201) {
        console.log("Subscription successful");
        setSuccess(true);
        // Clear the email input after successful submission
        setEmail("");
      }
    } catch (error) {
      console.error("Error subscribing:", error);
      setError(
        error.response.data.error ||
          "Failed to subscribe. Please try again later."
      );
    }

    setProcessing(false); // Reset processing state after submission is done
  };

  return (
    <div className="bg-blue-200 text-black rounded-3xl">
      <div className="max-w-3xl mx-auto text-center">
        <h3 className="text-4xl font-extrabold pt-10 text-black-600">
          Newsletter
        </h3>
        <p className="text-sm mt-6">
          Subscribe to our newsletter and stay up to date with the latest news,
          updates, and exclusive offers. Get valuable insights. Join our
          community today!
        </p>
        <form className="pb-5" onSubmit={handleSubmit}>
          <div className="max-w-lg mx-auto bg-gray-100 flex px-2 py-1 rounded-full text-left mt-10 border focus-within:border-gray-700">
            <input
              type="email"
              placeholder="Enter your email"
              className="w-full outline-none bg-transparent text-sm px-4 py-3"
              value={email}
              onChange={handleEmailChange}
            />
            <button
              type="submit"
              className="bg-green-700  hover:bg-gray-800 transition-all text-white font-semibold text-sm rounded-full px-8 py-3"
              disabled={processing}
            >
              Submit
            </button>
          </div>
          {processing && <p className=" mt-5">Processing...</p>}
          {error && <p className="text-red-800 mt-2 text-bold">{error}</p>}
          {success && (
            <p className="text-green-800 mt-5 text-bold">Subscription successful!</p>
          )}
        </form>
      </div>
      <div className="bg-slate-300 pt-6 sm:pt-6 sm:pb-3 mt-4 ">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-slate-700">
            {/* Logo and About */}
            <div className="flex flex-col gap-4">
              <div className="sm:flex justify-center items-center ml-1 ">
                <img className="h-20 w-auto " src={aarya} alt="Footer items-center" />
              </div>
              <h1 className="leading-10 text-l sm:text-[22px] text-center font-[600] text-black font-semibold ">
                A CONSCIOUS INVOICE
              </h1>
            </div>
            <div className="flex flex-col gap-4">
              <p className="leading-8 sm:text-[16px] text-center font-small ">
                ashish@consciousgrp.com
              </p>
            </div>
                      </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-6 sm:mt-8 flex items-center justify-center text-red text-sm sm:text-base justify-start">
          <p>Copyright ©️2024 Conscious Tech. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
