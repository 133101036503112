import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const licenceReducer = createReducer(initialState, {

  getLicenseDetailsRequest: (state) => {
    state.isLoading = true;
  },
  getLicenseDetailsSuccess: (state, action) => {
    state.isLoading = false;
    state.licenseDetail = action.payload;
  },
  getLicenseDetailsFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  });
