import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from "../server";
import { toast } from 'react-toastify';

const Licences = () => {
  const [email, setemail] = useState("");
  const [licenseNumber, setlicenseNumber] = useState("");
  const [liccreatedDate, setcreatedDate] = useState("");
  const [licyears, setyears] = useState("");
  const [licmonths, setmonths] = useState("");
  const [licdays, setdays] = useState("");
  const [licexpireDate, setexpireDate] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (liccreatedDate && licyears && licmonths && licdays) {
    const expireDateObj = new Date(liccreatedDate);
    expireDateObj.setFullYear(expireDateObj.getFullYear() + parseInt(licyears));
    expireDateObj.setMonth(expireDateObj.getMonth() + parseInt(licmonths));
    expireDateObj.setDate(expireDateObj.getDate() + parseInt(licdays));

    setexpireDate(expireDateObj.toISOString());
    }
}, [liccreatedDate, licyears, licmonths, licdays]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/shop/update-seller-license`,
        {
          email, licenseNumber, liccreatedDate, licyears, licmonths, licdays, licexpireDate
        },
      )
      .then((res) => {
        toast.success("License info updated succesfully!");
        setemail("");
        setlicenseNumber("");
        setcreatedDate("");
        setyears("");
        setmonths("");
        setdays("");
        setexpireDate("");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">License Information</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="licenseNumber" className="block text-sm font-medium text-gray-700">
                License Number
              </label>
              <input
                id="licenseNumber"
                name="licenseNumber"
                type="number"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                value={licenseNumber}
                onChange={(e) => setlicenseNumber(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="createdDate" className="block text-sm font-medium text-gray-700">
                Created Date
              </label>
              <input
                id="createdDate"
                name="createdDate"
                type="date"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                value={liccreatedDate}
                onChange={(e) => setcreatedDate(e.target.value)}
              />
            </div>

            <div className="grid grid-cols-3 gap-4">
              <div>
                <label htmlFor="years" className="block text-sm font-medium text-gray-700">
                  Years
                </label>
                <input
                  id="years"
                  name="years"
                  type="number"
                  min="0"
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                  value={licyears}
                  onChange={(e) => setyears(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="months" className="block text-sm font-medium text-gray-700">
                  Months
                </label>
                <input
                  id="months"
                  name="months"
                  type="number"
                  min="0"
                  max="11"
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                  value={licmonths}
                  onChange={(e) => setmonths(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="days" className="block text-sm font-medium text-gray-700">
                  Days
                </label>
                <input
                  id="days"
                  name="days"
                  type="number"
                  min="0"
                  max="30"
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                  value={licdays}
                  onChange={(e) => setdays(e.target.value)}
                />
              </div>
            </div>

            <div>
              <label htmlFor="expireDate" className="block text-sm font-medium text-gray-700">
                Expire Date
              </label>
              <input
                id="expireDate"
                name="expireDate"
                type="text"
                readOnly
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md bg-gray-100"
                value={licexpireDate}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="mt-4 p-2 w-full flex justify-center items-center border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Licences;
