import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProduct } from "../../redux/actions/product";
import { toast } from "react-toastify";
import { getAllProductsShop } from "../../redux/actions/product";
import { getAllEventsShop } from "../../redux/actions/event";

const ItemDetailsForm = ({ itemsList, setItemsList }) => {

  const handleAddItem = () => {
    setItemsList((prevItems) => [
      ...prevItems,
      {
        description: "",
        stock: "",
        unit: "",
        unitprice: "",
        productamount: "",
        discount: "",
        discountPrice: "",
        vatrate: "",
        amtaftervat: "",
      },
    ]);
  };

  const handleItemChange = (index, fieldName, value) => {
    setItemsList((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index][fieldName] = value;
      return updatedItems;
    });
  };

  const handleRemoveItem = (index) => {
    setItemsList((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });
  };

  useEffect(() => {
    itemsList.forEach((item, index) => {
      const stockValue = parseFloat(item.stock);
      const unitpriceValue = parseFloat(item.unitprice);
        const amountValue = stockValue * unitpriceValue;
          setItemsList((prevItems) => {
            const updatedItems = [...prevItems];
            if (updatedItems[index]) {
              updatedItems[index].productamount = amountValue.toFixed(2);
            }
            return updatedItems;
          });
    });
  }, [itemsList]);

  useEffect(() => {
    itemsList.forEach((item, index) => {
      const totalamount = parseFloat(item.productamount);
      const discountrate = parseFloat(item.discount);
      const discountedAmount = totalamount - (totalamount * discountrate / 100);

          setItemsList((prevItems) => {
            const updatedItems = [...prevItems];
            if (updatedItems[index]) {
              updatedItems[index].discountPrice = discountedAmount.toFixed(2);
            }
            return updatedItems;
          });
    });
  }, [itemsList]);

  useEffect(() => {
    itemsList.forEach((item, index) => {
      const distotalamount = parseFloat(item.discountPrice);
      const VatRate = parseFloat(item.vatrate);
      const AmtAVAT = distotalamount + (distotalamount * VatRate / 100);

          setItemsList((prevItems) => {
            const updatedItems = [...prevItems];
            if (updatedItems[index]) {
              updatedItems[index].amtaftervat = AmtAVAT.toFixed(2);
            }
            return updatedItems;
          });
    });
  }, [itemsList]);
  
  return (
    <>
      {itemsList.map((item, index) => (
        <div key={index} className="">
          <div className="border-solid border-2 border-black p-3">
        <div>
          <label className="pb-2">
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="4"
            type="text"
            name="description"
            value={item.description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-xs"
            onChange={(e) => handleItemChange(index, "description", e.target.value)}
            placeholder="Enter your product/services description..."
          ></textarea>
        </div>
        <br />
        <div>
          <label className="pb-2">
          Quantity <span className="text-red-500">*</span>
          </label>
          <input
          required
            type="number"
            name="quantity"
            value={item.stock}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => handleItemChange(index, "stock", e.target.value)}
            placeholder="Enter your quantity or 1"
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Unit
          </label>
          <input
            type="text"
            name="unit"
            value={item.unit}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => handleItemChange(index, "unit", e.target.value)}
            placeholder="Enter your product/services description..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Unit Price <span className="text-red-500">*</span>
          </label>
          <input
          required
            type="number"
            name="Unitprice"
            value={item.unitprice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => handleItemChange(index, "unitprice", e.target.value)}
            placeholder="Enter per unit price..."
          /> 
        </div>

        <br />
        <div>
          <label className="pb-2">Amount</label>
          <input
            name="amount"
            value={item.productamount} 
            onChange={(e) => handleItemChange(index, "productamount", e.target.value)}
            readOnly // Make the input read-only
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>
        <br />
        <div>
          <label className="pb-2">Discount(%) </label> <span className="text-red-500">*</span>
          <input
          required
            type="number"
            name="discount"
            value={item.discount}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => handleItemChange(index, "discount", e.target.value)}
            placeholder="Enter discount or enter 0"
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Amount (After Discount)
          </label>
          <input
            type="number"
            name="price"
            value={item.discountPrice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => handleItemChange(index, "discount", e.target.value)}
            readOnly // Make the input read-only
          />
        </div>
        <br />
        <div>
          <label className="pb-2">VAT(%) </label> <span className="text-red-500">*</span>
          <input
          required
            type="number"
            name="vat"
            value={item.vatrate}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => handleItemChange(index, "vatrate", e.target.value)}
            placeholder="Enter VAT Rate or 0"
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Amount (After VAT)
          </label>
          <input
            type="number"
            name="price"
            value={item.amtaftervat}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => handleItemChange(index, "amtaftervat", e.target.value)}
            readOnly // Make the input read-only
          />
        </div>    
      </div>
          {/* Include remove button for each item */}
          <button
            type="button"
            onClick={() => handleRemoveItem(index)}
            className="text-red-600 py-2 font-[600] p-2 my-1 text-[20px]"
          >
            Remove Above Item
          </button>
        </div>
      ))}
      <button className="text-green-600 font-[600] p-2 text-[20px]" type="button"  onClick={handleAddItem}>
        Add Item
      </button>
    </>
  );
};

const CreateProduct = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.products);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [images, setImages] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [term, setTerm] = useState("");
  const [selectedParty, setSelectedParty] = useState(null);
  const [eventDetails, setEventDetails] = useState(null);
  const { events, isLoading: eventsLoading } = useSelector((state) => state.events);
  const [totalProductAmount, setTotalProductAmount] = useState(0);
  const [totalDiscountedAmount, setTotalDiscountedAmount] = useState(0);
  const [totalVATAmount, setTotalVATAmount] = useState(0);
  const [totalInvoice, setTotalInvoice] = useState(0);
  const [TotalafterlDiscountedAmount, setTotalafterlDiscountedAmount] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState(0); // State to track invoice number
  const { allProducts, isLoading: productsLoading } = useSelector((state) => state.products);

  useEffect(() => {

     // Filter products based on seller._id and shopId
  const filteredProducts = allProducts.filter(
    (item) => item.shopId === seller._id
  );
    // Fetch the last invoice number from allProducts
    const lastInvoice = filteredProducts.reduce((max, product) => {
      if (product.invoiceNumber > max) {
        return product.invoiceNumber;
      }
      return max;
    }, 0);
  
    // Set the invoice number
    setInvoiceNumber(lastInvoice > 0 ? lastInvoice + 1 : 1);
  }, [allProducts, seller]);


  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      navigate("/dashboard-invoices");
      window.location.reload();
      toast.success("Invoice created successfully!");
    }
  }, [dispatch, error, success]);

  useEffect(() => {
    if (selectedParty) {
      const selectedEvent = events.find(event => event._id === selectedParty);
      setEventDetails(selectedEvent);
    } else {
      setEventDetails(null);
    }
  }, [selectedParty, events]);

  useEffect(() => {
    dispatch(getAllProductsShop(seller._id));
    dispatch(getAllEventsShop(seller._id));
  }, [dispatch, seller._id]);


  useEffect(() => {
    // Calculate total product amount
    const sumProductAmount = itemsList.reduce((total, item) => total + parseFloat(item.productamount), 0);
    setTotalProductAmount(sumProductAmount.toFixed(2));
  
    // Calculate total discounted amount
    const sumDiscountedAmount = itemsList.reduce((total, item) => total + parseFloat(item.productamount-item.discountPrice), 0);
    setTotalDiscountedAmount(sumDiscountedAmount.toFixed(2));
  
     // Calculate total amount after discount
     const afterDiscountedAmount = itemsList.reduce((total, item) => total + parseFloat(item.discountPrice), 0);
     setTotalafterlDiscountedAmount(afterDiscountedAmount.toFixed(2));
   
    // Calculate total VAT amount
    const sumVATAmount = itemsList.reduce((total, item) => total + parseFloat(item.amtaftervat-item.discountPrice), 0);
    setTotalVATAmount(sumVATAmount.toFixed(2));
  
    // Calculate total invoice
    const sumTotalInvoice = sumProductAmount - sumDiscountedAmount + sumVATAmount;
    setTotalInvoice(sumTotalInvoice.toFixed(2));
  }, [itemsList]);
  

  
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const totalImages = images.length + files.length;

    if (totalImages <= 1) {
      files.forEach((file) => {
        const reader = new FileReader();

        reader.onload = () => {
          if (reader.readyState === 2) {
            const image = new Image();
            image.src = reader.result;

            image.onload = () => {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");

              const MAX_HEIGHT = 400; // Maximum height

              let width = image.width;
              let height = image.height;

              // Calculate the width while maintaining aspect ratio
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }

              canvas.width = width;
              canvas.height = height;

              ctx.drawImage(image, 0, 0, width, height);

              const resizedDataUrl = canvas.toDataURL("image/jpeg", 0.7); // Adjust the quality (0.7 is 70% quality)

              setImages((old) => [...old, resizedDataUrl]);
            };
          }
        };
        reader.readAsDataURL(file);
      });
    } else {
      toast.error("You can upload only one images.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);

    // Check if selectedParty is blank or "Choose a selected Party"
  if (!selectedParty || !eventDetails) {
    toast.error("Please select party");
    setProcessing(false);
    return; // Exit the function early if party is not selected
  }

    const newForm = new FormData();

    images.forEach((image) => {
      newForm.set("images", image);
    });

    newForm.append("invoiceNumber", invoiceNumber);
    newForm.append("startDate", startDate);
    newForm.append("endDate", endDate);
    newForm.append("selectedParty", selectedParty);
    newForm.append("totalProductAmount", totalProductAmount);
    newForm.append("totalDiscountedAmount", totalDiscountedAmount);
    newForm.append("TotalafterlDiscountedAmount", TotalafterlDiscountedAmount); 
    newForm.append("totalVATAmount", totalVATAmount);
    newForm.append("totalInvoice", totalInvoice);
    newForm.append("term", term);
  
    itemsList.forEach((item, index) => {
      // Append each item as an object in the form
      newForm.append(`items[${index}][description]`, item.description);
      newForm.append(`items[${index}][stock]`, item.stock);
      newForm.append(`items[${index}][unit]`, item.unit);
      newForm.append(`items[${index}][unitprice]`, item.unitprice);
      newForm.append(`items[${index}][discount]`, item.discount);
      newForm.append(`items[${index}][vatrate]`, item.vatrate);
      newForm.append(`items[${index}][productamount]`, item.productamount);
      newForm.append(`items[${index}][discountPrice]`, item.discountPrice);
      newForm.append(`items[${index}][amtaftervat]`, item.amtaftervat);
    });

    dispatch(
      createProduct({
        shopId: seller._id,
        images,
        startDate,
        endDate,
        selectedParty,
        totalProductAmount,
        totalDiscountedAmount,
        TotalafterlDiscountedAmount, 
        totalVATAmount,
        totalInvoice,
        itemsList, // Pass your array of item details here
        invoiceNumber,
        term,
      })
    ).then((response) => {
      setProcessing(false);
      if (response && response.success) {
        const productId = response.data.productId; // Replace with the actual key to access the product ID in the response
        navigate(`/update-product/${productId}`);
      }
    });
  };

  return (
    <div className="w-[90%] 800px:w-[60%] bg-white shadow h-full rounded-[4px] p-5 800px:p-8 overflow-y-scroll">
      <div className="flex pb-3 justify-between"><h5 className="text-[22px] font-Poppins text-center">Create Invoice</h5><p className="text-[18px]">#{seller.description}00000{invoiceNumber}</p></div>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
      <div>
          <label className="pb-2">
            Invoice Date <span className="text-red-500">*</span>
          </label>
          <input
          required
            type="date"
            name="invoice-date"
            id="start-date"
            value={startDate}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Enter your event product stock..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Due Date <span className="text-red-500">*</span>
          </label>
          <input
            required
            type="date"
            name="due-date"
            id="end-date"
            value={endDate}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="Enter your event product stock..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Name of Party <span className="text-red-500">*</span>
          </label>
          <select
                    required
                      className="w-full mt-2 border h-[35px] rounded-[5px]"
                      value={selectedParty}
                      onChange={(e) => setSelectedParty(e.target.value)}
                    >
                      <option value="Choose your selected Party">
                        Choose a selected Party
                      </option>
                      {events &&
                        events.map((i) => (
                          <option value={i._id} key={i._id}>
                            {i.name}
                          </option>
                        ))}
                    </select>
                    {eventDetails && (
        <div className="pt-2">
          <p>Address: {eventDetails.description}</p>
          <p>TRN: {eventDetails.category}</p>
          <p>Contact Person: {eventDetails.tags}</p>
          <p>Contact No: {eventDetails.originalPrice}</p>
          {/* Add other details here */}
        </div>
      )}
        </div>
        <br />
        <ItemDetailsForm itemsList={itemsList} setItemsList={setItemsList} />
      <br />
      <br />
      {/* Display Total Calculations */}
      <div>
                <h4>Total Items Price: {seller.currency}{totalProductAmount}</h4>
                <h4>Total Discounted Amount: {seller.currency}{totalDiscountedAmount}</h4>
                <h4>Taxable Amount: {seller.currency}{TotalafterlDiscountedAmount}</h4>
                <h4>VAT Amount: {seller.currency}{totalVATAmount}</h4>
                <h4>Total Invoice: {seller.currency}{totalInvoice}</h4>
              </div>
        <div>
          <br />
          <div>
          <label className="pb-2">
            Terms and Conditions
          </label>
          <textarea
            cols="30"
            rows="4"
            type="text"
            name="term"
            value={term}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-xs"
            onChange={(e) => setTerm(e.target.value)}
            placeholder="Enter bill teams or notes if any..."
          ></textarea>
        </div>
          {/* <br />
          <label className="pb-2">
            Upload supporting(If needed)
          </label>
          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />
          <div className="w-full flex items-center flex-wrap">
            <label htmlFor="upload">
              <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
            </label>
            {images &&
              images.map((img, index) => (
                <img
                  src={img}
                  key={index}
                  alt=""
                  className="h-[120px] w-[120px] object-cover m-2"
                />
              ))}
          </div> */}
          <br />
          <div>
            <input
              type="submit"
              value={processing ? "Processing..." : "Create"}
              className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              disabled={processing} // Disable the button while processing
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateProduct;
