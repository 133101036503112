import { React, useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

const ShopLogin = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetPasswordSent, setResetPasswordSent] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(`${server}/shop/reset-password/${token}`, {
        newPassword: password,
        confirmPassword: password,
      });
      toast.success("Password updated successfully");
      navigate("/user-login");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(
        `${server}/shop/login-shop`,
        {
          email,
          password,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Login Success!");
        navigate("/dashboard");
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleForgotPassword = async () => {
    setLoading(true);
    try {
      await axios.post(`${server}/shop/forgot-password`, { email });
      setResetPasswordSent(true);
    } catch (error) {
      console.log(error); // Log the error object to see its structure
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Check if token exists in URL params
    if (token) {
      // If token exists, show reset password form
      setShowResetForm(true);
    }
  }, [token]);
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col py-1 sm:px-6 lg:px-8">
      <div className="flex justify-center mb-16">
      <Link to="/"><button
                      className="mt-4 text-white font-semibold rounded bg-transparent border px-10 py-4 hover:text-white transition-colors duration-300"
                      style={{
                        borderRadius: "8px",
                        backgroundImage:
                          "linear-gradient(to right, #0000FF, #FF0000)",
                        backgroundSize: "200% auto",
                        transition: "background-position 0.5s",
                        fontSize: "1rem", // Adjust the font size as needed
                        textAlign: "center", // Center the text horizontally
                        width: "fit-content", // Make the button width fit its content
                        fontWeight: "700"
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundPosition = "right")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundPosition = "left")
                      }
                    >
                    Return to Home Page
                    </button></Link>  
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900">
          Login to your Account
        </h2>
      </div>
      <div className="mt-8 mb-1 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-5 px-4 shadow sm:rounded-lg sm:px-10 bg-slate-300">
          {showResetForm ? (
            <form className="space-y-6" onSubmit={handleResetPassword}>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  New Password
                </label>
                <div className="mt-1 relative">
                  <input
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className={`${styles.noramlFlex} justify-between`}>
                <div></div>
                <div className="text-sm">
                  <button
                    type="submit"
                    className={`${
                      loading
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-blue-600 hover:bg-blue-700"
                    } group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white`}
                    disabled={loading}
                  >
                    {loading ? "Processing..." : "Reset Password"}
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <form className="space-y-6" onSubmit={handleSubmit}>
              {/* email input */}
              <div></div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              {/* password input */}
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    type={visible ? "text" : "password"}
                    name="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  {visible ? (
                    <AiOutlineEye
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(false)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(true)}
                    />
                  )}
                </div>
              </div>
              <div className={`${styles.noramlFlex} justify-between`}>
                <div className={`${styles.noramlFlex}`}>
                  <input
                    type="checkbox"
                    name="remember-me"
                    id="remember-me"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>
                <div className="text-sm">
                  {resetPasswordSent ? (
                    <p className="font-medium text-blue-600">
                      Password reset link has been sent to your email
                    </p>
                  ) : (
                    <button
                      type="button"
                      onClick={handleForgotPassword}
                      className={`${
                        loading
                          ? "bg-gray-400 cursor-not-allowed"
                          : "bg-blue-600 hover:bg-blue-700"
                      } group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white`}
                      disabled={loading}
                    >
                      {loading ? "Processing..." : "Forgot your password?"}
                    </button>
                  )}
                </div>
              </div>
              {/* submit button */}
              <div>
                <button
                  type="submit"
                  className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                >
                  Submit
                </button>
              </div>
              {/* sign up link */}
              <div className={`${styles.noramlFlex} w-full`}>
                <h4>Not have any account?</h4>
                <Link to="/create-user" className="text-blue-800 pl-2">
                  Sign Up
                </Link>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShopLogin;
