import { useState } from "react";
import DashboardSideBar from "../components/Shop/Layout/DashboardSideBar";
import DashboardHeader from "../components/Shop/Layout/DashboardHeader";
import Whatsapp from "../components/Whatsapp/whatsapp";
import { useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../src/server";
import { CiLogout } from "react-icons/ci";
import { RxDashboard } from "react-icons/rx";
import { Navigate } from "react-router-dom";

const Licenseexpire = () => {
  const { seller } = useSelector((state) => state.seller);
  const [usermessage, setUserMessage] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const email = seller.email;

  const [processing, setProcessing] = useState(false);
  const handleClick = () => {
    window.scrollTo(0, 0); // Scroll to the top
  };
  const handleMessageChange = (e) => {
    setUserMessage(e.target.value);  
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false); // Reset success state

    if (!usermessage) {
      setError("Message is required.");
      return;
    }

    setProcessing(true); // Set processing to true when form is submitted

    try {
      const response = await axios.post(`${server}/licence/subscribe`, {
        email, 
        usermessage,
      });

      if (response.status === 201) {
        console.log("Notification send successful. We will contact you through email within 1 Hour");
        setSuccess(true);
        // Clear the email input after successful submission
        setUserMessage("");
      }
    } catch (error) {
      console.error("Error subscribing:", error);
      setError(
        error.response.data.error ||
          "Failed to sending notification. Please try again later."
      );
    }

    setProcessing(false); // Reset processing state after submission is done
  };

  
const logoutHandler = async () => {
  axios.get(`${server}/shop/logout`,{
    withCredentials: true,
  });
  window.location.reload();
};

  

  return (
    <div className="flex bg-white">
        <Whatsapp />
      <div className="px-10 w-full">
        <div className="py-5 flex justify-center font-[500] text-[20px]">
          Welcome&nbsp;
          <span className="font-[700] text-green-900 bg-gray-300 shadow-lg px-2 rounded-sm">{email}</span><span className="text-black">&nbsp;at our Invoice Application !!!</span> 
        </div>
        <div className="bg-blue-200 font-[sans-serif] text-black rounded-3xl">
        <div className="max-w-3xl mx-auto text-center">
        <h3 className="font-[700] text-[25px] text-red-800 pt-5">
        Your subscription has expired !!!
        </h3>
        <p className="text-sm mt-3">
        Your subscription has expired, but don't worry! subscribe or Renew now to continue enjoying our services. 
        </p>
        <form className="pb-5" onSubmit={handleSubmit}>
          <div className="max-w-full mx-auto bg-gray-100 flex px-2 py-1 rounded-full text-left mt-3 border focus-within:border-gray-700">
            <input
              type="message"
              placeholder="Enter your message"
              className="w-full outline-none bg-transparent text-sm px-4 py-3"
              value={usermessage}
              onChange={handleMessageChange}
            />
            <button
              type="submit"
              className="bg-green-700 hover:bg-gray-800 transition-all text-white font-semibold text-sm rounded-full px-8 py-3"
              disabled={processing}
            >
              Send
            </button>
          </div>
          {processing && <p className=" mt-5 font-[600] text-green-800">Processing...</p>}
          {error && <p className="text-red-800 mt-5 font-[600] text-bold">{error}</p>}
          {success && (
            <p className="text-green-800 mt-5 font-[600] text-bold">Subscription request send successful !!!</p>
          )}
        </form>
      </div>
      </div>
      <br />
      <br />
      <div className="w-full flex items-center p-4">
          <CiLogout className="cursor-pointer"
          onClick={logoutHandler}
            size={30}
          />
          <h5 onClick={logoutHandler}
            className="block pl-2 text-[18px] cursor-pointer"
          >
            <span className="text-red-900 font-[700]">LOG OUT</span>
          </h5>
      </div>
      </div>
    </div>
  );
};


export default Licenseexpire;
