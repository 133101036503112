import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ShopLogin from "../components/Shop/ShopLogin";
import Footer from "../components/Layout/Footer";
import Whatsapp from "../components/Whatsapp/whatsapp";


const ShopLoginPage = () => {
  const navigate = useNavigate();
  const { isSeller,isLoading } = useSelector((state) => state.seller);

  useEffect(() => {
    document.title = 'Login - Conscious Invoice';
  }, []);
  

  useEffect(() => {
    if(isSeller === true){
      navigate(`/dashboard`);
    }
  }, [isLoading, isSeller])
  return (
    <div>
        <ShopLogin />
        <Whatsapp />
      <Footer />
    </div>
  )
}

export default ShopLoginPage