import React, { useEffect } from 'react';
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader'
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar'
import UpdateProduct from "../../components/Shop/UpdateProduct";

const ShopUpdateProduct = () => {

  useEffect(() => {
    document.title = 'Update Invoice - Conscious Invoice';
  }, []);


    return (
        <div>
            <DashboardHeader />
            <div className="flex justify-between w-full">
                <div className="w-[80px] 800px:w-[330px]">
                  <DashboardSideBar active={4} />
                </div>
                <div className="w-full mt-5 justify-center flex">
                    <UpdateProduct />
                </div>
              </div>
        </div>
      )
    }

export default ShopUpdateProduct