import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createevent } from "../../redux/actions/event";

const CreateEvent = () => {
  const { seller } = useSelector((state) => state.seller);
  const { error } = useSelector((state) => state.events);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [originalPrice, setOriginalPrice] = useState();
  
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [dispatch, error]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Set processing state to true
    setProcessing(true);
    
    const data = {
      name,
      description,
      category,
      tags,
      originalPrice,
      shopId: seller._id,
    };
    dispatch(createevent(data));
    navigate("/dashboard-customers");
  };

  return (
    <div className="w-[90%] 800px:w-[60%] bg-white shadow h-full rounded-[4px] p-5 800px:p-8 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">Create Customer</h5>
      {/* create event form */}
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Name <span className="text-red-500">*</span>
          </label>
          <input
          required
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Party Name..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Address <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="4"
            type="text"
            name="Address"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Party Full Address..."
          ></textarea>
        </div>
        <br />
        <div>
          <label className="pb-2">
            TRN
          </label>
          <input
            type="text"
            name="TRN"
            value={category}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setCategory(e.target.value)}
            placeholder="Party TRN Number..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Contact Person<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="Contact-person"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Party Contact Person..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">Contact Number</label>
          <input
            type="number"
            name="contact-number"
            value={originalPrice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setOriginalPrice(e.target.value)}
            placeholder="Enter Contact Number..."
          />
        </div>
        <br />
        <div>  
          <div>
            
              <input
                type="submit"
                value={processing ? "Processing..." : "Create"}
                disabled={processing} // Disable the button while processing 
                className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateEvent;
