import React, { useEffect } from 'react';
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader'
import CreateEvent from "../../components/Shop/CreateEvent";
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';

const ShopCreateEvents = () => {

  useEffect(() => {
    document.title = 'Create Customer - Conscious Invoice';
  }, []);


  return (
    <div>
        <DashboardHeader />
        <div className="flex justify-between w-full">
      <div className="w-[80px] 800px:w-[330px]">
        <DashboardSideBar active={6} />
      </div>
      <div className="w-full mt-5 justify-center flex">
        <CreateEvent />
      </div>
    </div>
    </div>
  )
}

export default ShopCreateEvents