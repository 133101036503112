import React, { useEffect } from 'react';
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader';
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import CreateProduct from "../../components/Shop/CreateProduct";
import { useNavigate } from "react-router-dom";


const ShopCreateProduct = () => {
  const navigate = useNavigate();
  navigate("/dashboard-customers");

  useEffect(() => {
    document.title = 'Create Invoice - Conscious Invoice';
  }, []);

  return (
    <div>
        <DashboardHeader />
        <div className="flex justify-between w-full">
            <div className="w-[80px] 800px:w-[330px]">
              <DashboardSideBar active={4} />
            </div>
            <div className="w-full mt-5 justify-center flex">
                <CreateProduct />
            </div>
          </div>
    </div>
  )
}
export default ShopCreateProduct