import { createReducer } from "@reduxjs/toolkit";
const initialState = {
  cart: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
};
export const cartReducer = createReducer(initialState, {
  addToCart: (state, action) => {
    const item = action.payload;
    const isItemExist = state.cart.find((i) => i._id === item._id);

    if (isItemExist) {
      const remainingStock = item.stock - isItemExist.qty;
      if (remainingStock <= 0) {
        console.error("Requested quantity exceeds available stock!");
        return state;
      } else {
        const requestedQuantity = isItemExist.qty + 1;
        if (requestedQuantity > item.stock) {
          console.error("Requested quantity exceeds available stock!");
          return state;
        }
        return {
          ...state,
          cart: state.cart.map((i) =>
            i._id === isItemExist._id ? { ...i, qty: i.qty + 1 } : i
          ),
        };
      }
    } else {
      if (item.stock < 1) {
        console.error("Product is out of stock!");
        return state;
      } else {
        if (item.stock === 1) {
          console.error("Requested quantity exceeds available stock!");
          return state;
        }
        return {
          ...state,
          cart: [...state.cart, { ...item, qty: 1 }],
        };
      }
    }
  },

  removeFromCart: (state, action) => {
    return {
      ...state,
      cart: state.cart.filter((i) => i._id !== action.payload),
    };
  },
});
