import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { updateEvent } from "../../redux/actions/event";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";
import { getAllProductsShop } from "../../redux/actions/product";
import { getAllEventsShop } from "../../redux/actions/event";

const UpdateCustomer = () => {
  const { id } = useParams();
  const { seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();
  const { events, isLoading: eventsLoading } = useSelector((state) => state.events);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [originalPrice, setOriginalPrice] = useState();
  const [eventDetails, setEventDetails] = useState(null);
  const { error, success } = useSelector((state) => state.events);
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false); // State variable to track update success
  
  useEffect(() => {
    dispatch(getAllEventsShop(seller._id));
  }, [dispatch, seller._id]);
  
  useEffect(() => {
    if (!eventsLoading && events && id) {
      const selectedParty = id;
      if (selectedParty) {
        const selectedEvent = events.find((event) => event._id === selectedParty);
        setEventDetails(selectedEvent);
        setName(selectedEvent.name);
        setDescription(selectedEvent.description);
        setCategory(selectedEvent.category);
        setTags(selectedEvent.tags);
        setOriginalPrice(selectedEvent.originalPrice);
      }
    }
  }, [eventsLoading, events, id ]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      setUpdateSuccess(true); // Set update success to true
    }
  }, [error, success]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Show processing message
    setIsUpdating(true);

   
    try {
      await dispatch(
        updateEvent(id, {
          // Pass the id along with other product details
          name,
          description,
          category,
          tags,
          originalPrice,
        })
      );

      navigate("/dashboard-customers");
      window.location.reload();
      toast.success("Customer updated successfully!");
    } catch (error) {
      // Show error message if update fails
      toast.error("Failed to update product. Please try again later.");
    } finally {
      // Reset the updating state after navigating or updating fails
      setIsUpdating(false);
    }
  };
 
  return (
    <div className="w-[90%] 800px:w-[60%] bg-white shadow h-full rounded-[4px] p-5 800px:p-8 overflow-y-scroll">
      <div className="flex pb-3 justify-between"><h5 className="text-[22px] font-Poppins text-center">Update Customer</h5></div>
      <form onSubmit={handleSubmit}>
      <br />
        <div>
          <label className="pb-2">
            Name <span className="text-red-500">*</span>
          </label>
          <input
          required
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Party Name..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Address <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="4"
            type="text"
            name="Address"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Party Full Address..."
          ></textarea>
        </div>
        <br />
        <div>
          <label className="pb-2">
            TRN
          </label>
          <input
            type="text"
            name="TRN"
            value={category}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setCategory(e.target.value)}
            placeholder="Party TRN Number..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Contact Person<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="Contact-person"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Party Contact Person..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">Contact Number</label>
          <input
            type="number"
            name="contact-number"
            value={originalPrice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setOriginalPrice(e.target.value)}
            placeholder="Enter Contact Number..."
          />
        </div>
        <br />
          <div>
            <input
              type="submit"
              value="Update"
              className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
      </form>
    </div>
  );
};

export default UpdateCustomer;
