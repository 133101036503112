import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { backend_url, server } from "../../server";
import { AiOutlineCamera } from "react-icons/ai";
import styles from "../../styles/styles";
import axios from "axios";
import { loadSeller } from "../../redux/actions/user";
import { toast } from "react-toastify";



const ShopSettings = () => {
  const { seller } = useSelector((state) => state.seller);
  const [name, setName] = useState(seller && seller.name);
  const [currency, setCurrency] = useState(seller && seller.currency);
  const [currencycode, setCurrencycode] = useState(seller && seller.currencycode);
  const [description, setDescription] = useState(
    seller && seller.description ? seller.description : ""
  );
  const [bankDetails, setbankDetails] = useState(
    seller && seller.bankDetails ? seller.bankDetails : ""
  );
  const [note, setNote] = useState(
    seller && seller.note ? seller.note : ""
  );
  const [address, setAddress] = useState(seller && seller.address);
  const [phoneNumber, setPhoneNumber] = useState(seller && seller.phoneNumber);
  const [zipCode, setZipcode] = useState(seller && seller.zipCode);

  const dispatch = useDispatch();

  const handleImage = async (e) => {
    const formData = new FormData();
    formData.append('avatar', e.target.files[0]);

    try {
      const res = await axios.put(`${server}/shop/update-shop-avatar`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch(loadSeller());
      toast.success('Avatar updated successfully!');
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const updateHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/shop/update-seller-info`,
        {
          name,
          address,
          zipCode,
          phoneNumber,
          description,
          bankDetails,
          currency,
          note,
          currencycode,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Shop info updated succesfully!");
        dispatch(loadSeller());
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const [avatarUrl, setAvatarUrl] = useState('');
  useEffect(() => {
    const fetchAvatarUrl = async () => {
      try {
        const res = await axios.get(`${server}/shop/get-avatar-url`, {
          withCredentials: true,
        });
        setAvatarUrl(`${backend_url}${res.data.avatarUrl}`);
      } catch (error) {
        console.error('Error fetching avatar URL:', error);
      }
    };

    fetchAvatarUrl();
  }, []);
  
  return (
    <div className="w-full min-h-screen flex flex-col items-center">
      <div className="flex w-full 800px:w-[80%] flex-col justify-center my-5">
        <div className="w-full flex items-center justify-center">
          <div className="relative">
            <img
              src={avatarUrl}
              alt="Avatar"
              className="cursor-pointer max-h-[150px]"
            />
            <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[10px] right-[15px]">
              <input
                type="file"
                id="image"
                className="hidden"
                onChange={handleImage}
              />
              <label htmlFor="image">
                <AiOutlineCamera size={30}/>
              </label>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-2 font-[500]"><p>{seller.email}</p></div>

        {/* shop info */}
        <form
          aria-aria-required={true}
          className="flex flex-col items-center"
          onSubmit={updateHandler}
        >
          <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-2">
            <div className="w-full pl-[3%]">
              <label className="block pb-2">Company Name</label>
            </div>
            <input
              type="name"
              placeholder={`${seller.name}`}
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
            />
          </div>
          <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
            <div className="w-full pl-[3%]">
              <label className="block pb-2">Company Short Name</label>
            </div>
            <input
              type="name"
              placeholder={`${
                seller?.description
                  ? seller.description
                  : "Enter Company Short Name like: AC"
              }`}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
            />
          </div>
          <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
            <div className="w-full pl-[3%]">
              <label className="block pb-2">Company's Bank Details</label>
            </div>
            <textarea
              cols="30"
              rows="4"
              type="text"
              name="bankDetails"
              placeholder={`${
                seller?.bankDetails
                  ? seller.bankDetails
                  : "Enter Company's bank Details"
              }`}
              value={bankDetails}
              onChange={(e) => setbankDetails(e.target.value)}
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
            />
          </div>
          <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
            <div className="w-full pl-[3%]">
              <label className="block pb-2">Currency</label>
            </div>
            <input
              type="name"
              name="currency"
              placeholder={`${
                seller?.currency
                  ? seller.currency
                  : "Enter your currency like: AED, $, INR."
              }`}
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
            />
          </div>
          <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
            <div className="w-full pl-[3%]">
              <label className="block pb-2">Company Address</label>
            </div>
            <input
              type="name"
              placeholder={seller?.address}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
            />
          </div>

          <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
            <div className="w-full pl-[3%]">
              <label className="block pb-2">TRN</label>
            </div>
            <input
              type="number"
              placeholder={seller?.phoneNumber}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
            />
          </div>
          <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
            <div className="w-full pl-[3%]">
              <label className="block pb-2">Company's Note</label>
            </div>
            <textarea
              cols="30"
              rows="4"
              type="text"
              name="note"
              placeholder={`${
                seller?.note
                  ? seller.note
                  : "Enter Company's note"
              }`}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
            />
          </div>
          <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
            <div className="w-full pl-[3%]">
              <label className="block pb-2">Shop Zip Code</label>
            </div>
            <input
              type="number"
              placeholder={seller?.zipCode}
              value={zipCode}
              onChange={(e) => setZipcode(e.target.value)}
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
            />
          </div>
          <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
            <div className="w-full pl-[3%]">
              <label className="block pb-2">Currency Code</label>
            </div>
            <select
            type="name"
            name="currencycode"
            placeholder={`${
              seller?.currencycode
                ? seller.currencycode
                : "Enter your currency like: AED, $, INR."
            }`}
            value={currencycode}
            onChange={(e) => setCurrencycode(e.target.value)}
            className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
           >
            <option value="en-US">en-US</option>
            <option value="en-GB">en-GB</option>
            <option value="en-AE">en-AE</option>
            <option value="en-NP">en-NP</option>
            <option value="en-IN">en-IN</option>
          </select>
          </div>
          <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
            <input
              type="submit"
              value="Update Company"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              readOnly
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShopSettings;
