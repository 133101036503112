import React, { useEffect, useState } from "react";
import { AiOutlineArrowRight, AiOutlineDelete, AiOutlineEdit, AiOutlineEye, AiOutlineMoneyCollect } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { MdBorderClear } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { getAllProductsShop } from "../../redux/actions/product";
import { getAllEventsShop } from "../../redux/actions/event";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

const DashboardHero = () => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [rows, setRows] = useState([]); // Define rows state here
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0); // State for total invoice amount
  const [TotalVatAmount, setTotalVatAmount] = useState(0); // State for total invoice amount
  const { events, isLoading: eventsLoading } = useSelector((state) => state.events);

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
    dispatch(getAllEventsShop(seller._id));
    dispatch(getAllProductsShop(seller._id));
  }, [dispatch]);
  
  
  useEffect(() => {
    // Clone the allProducts array to avoid mutating the original array
    const clonedProducts = allProducts.slice();
    
    // Filter products based on seller._id and shopId
    const filteredProducts = clonedProducts.filter(
      (item) => item.shopId === seller._id
    );
  
    // Sort the cloned array based on invoiceNumber in descending order
    filteredProducts.sort((a, b) => b.invoiceNumber - a.invoiceNumber);
  
    const rows = filteredProducts.map((item) => ({
      id: item._id,
      inviceNo: `${seller.description}00000${item.invoiceNumber}`,
      date: new Date(item.startDate).toLocaleDateString(),
      due: new Date(item.endDate).toLocaleDateString(),
      party: item.selectedParty,
      taxableAmt: item.TotalafterlDiscountedAmount,
      vat: item.totalVATAmount,
      invoice: item.totalInvoice
    }));

    // Fetch event details for each selected party
    const fetchEventDetails = async () => {
      const eventDetailsArray = await Promise.all(
        rows.map(async (row) => {
          if (row.party) {
            const selectedEvent = events.find((event) => event._id === row.party);
            return { ...row, party: selectedEvent ? selectedEvent.name : '' };
          }
          return row;
        })
      );
      setRows(eventDetailsArray);
    };
  
    fetchEventDetails();
    
    // Calculate the total invoice amount
    const totalAmount = rows.reduce((total, row) => total + row.taxableAmt, 0);
    setTotalInvoiceAmount(totalAmount);

    const totalvatAmount = rows.reduce((total, row) => total + row.vat, 0);
    setTotalVatAmount(totalvatAmount.toFixed(2));
  
    // Set the rows state
    setRows(rows);
  }, [allProducts, seller, events]);

  const availableBalance = totalInvoiceAmount.toFixed(2);

  const columns = [
    { field: "id", headerName: "Id", minWidth: 100 },
    { field: "inviceNo", headerName: "Invoice#", minWidth: 150 },
    { field: "date", headerName: "Invoice Date", minWidth: 180 },
    { field: "due", headerName: "Due Date", minWidth: 180 },
    { field: "party", headerName: "Name of Party", minWidth: 180},
    { field: "taxableAmt", headerName: "Taxable Amount", minWidth: 180 },
    { field: "vat", headerName: "VAT(if Any)", minWidth: 180 },
    { field: "invoice", headerName: "Invoice Amount", minWidth: 180 },
    {
      field: "Preview",
      minWidth: 120,
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Link to={`/invoice/${params.id}`}>
          <Button>
            <AiOutlineEye size={20} />
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <div className="w-full p-8">
      <h3 className="text-[22px] font-Poppins pb-2">Overview</h3>
      <div className="w-full block 800px:flex items-center justify-between">
        <div className="w-full mb-4 800px:w-[30%] min-h-[20vh] bg-white shadow rounded px-2 py-5">
          <div className="flex items-center">
            <AiOutlineMoneyCollect
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
            >
              Total - Sales{" "}
              <span className="text-[16px]">(Taxable)</span>
            </h3>
          </div>
          <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">{seller.currency}{availableBalance}</h5>
          <Link to="/dashboard-invoices">
            <h5 className="pt-4 pl-[2] text-[#077f9c]">Total Sales Details</h5>
          </Link>
        </div>

        <div className="w-full mb-4 800px:w-[30%] min-h-[20vh] bg-white shadow rounded px-2 py-5">
          <div className="flex items-center">
            <AiOutlineMoneyCollect
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
            >
              VAT - AMOUNT{" "}
              <span className="text-[16px]"></span>
            </h3>
          </div>
          <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">{seller.currency}{TotalVatAmount}</h5>
          <Link to="/dashboard-invoices">
            <h5 className="pt-4 pl-[2] text-[#077f9c]">Total VAT Details</h5>
          </Link>
        </div>

        <div className="w-full mb-4 800px:w-[30%] min-h-[20vh] bg-white shadow rounded px-2 py-5">
          <div className="flex items-center">
            <AiOutlineMoneyCollect
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
            >
              All Invoices
            </h3>
          </div>
          <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">{products && products.length}</h5>
          <Link to="/dashboard-invoices">
            <h5 className="pt-4 pl-2 text-[#077f9c]">View Invoices</h5>
          </Link>
        </div>

      </div>
      <br />
      <h3 className="text-[22px] font-Poppins pb-2">Latest Invoices</h3>
      <div className="w-full min-h-[45vh] bg-white rounded">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
      </div>
    </div>
  );
};

export default DashboardHero;
