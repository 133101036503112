import React, { useState, useEffect, useRef } from "react";
import about from "./about.jpeg";
import { Link } from "react-router-dom";


const AboutUs = () => {
  const [expanded, setExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const wordsLimit = 100; // Define the number of words to display initially
  const paragraphRef = useRef(null);

  useEffect(() => {
    if (paragraphRef.current) {
      const text = paragraphRef.current.innerText;
      const words = text.split(" ");
      setShowButton(words.length > wordsLimit);
    }
  }, []);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <section className=" font-[sans-serif] ml-auto mr-auto mb-10 p-5 flex items-center justify-center shadow-sm shadow-slate-800 rounded-3xl max-w-[1440px] bg-slate-100">
        <div className="justify-center flex-1 max-w-6xl mb-22 mx-auto lg:py-6 md:px-6 animate-fade-up animate-once">
          <div className="xl:pb-20 pb-10" style={{ display: "flex", justifyContent: "center" }}>
                 <Link to="/user-login"><button
                      className="mt-4 text-white font-semibold rounded bg-transparent border px-10 py-4 hover:text-white transition-colors duration-300"
                      style={{
                        borderRadius: "8px",
                        backgroundImage:
                          "linear-gradient(to right, #0000FF, #FF0000)",
                        backgroundSize: "200% auto",
                        transition: "background-position 0.5s",
                        fontSize: "1.6rem", // Adjust the font size as needed
                        textAlign: "center", // Center the text horizontally
                        width: "fit-content", // Make the button width fit its content
                        fontWeight: "700"
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundPosition = "right")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundPosition = "left")
                      }
                    >
                    Create your Own Invoice with Us
                    </button></Link>  
          </div>
          
          <div className="flex flex-wrap">
            <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0">
              <div className="relative">
                <img
                  src={about}
                  alt=""
                  className="rounded-xl w-full h-full"
                />
                <div className="absolute z-50 hidden lg:block w-full bottom-0 bg-blue-300 rounded-bl-[80px] rounded"></div>
              </div>
            </div>
            <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0 ">
              <div className="relative">
                <h1 className="pl-2 text-2xl font-bold border-l-8 border-fuchsia-800 md:text-5xl">
                  Who are we?
                </h1>
              </div>
              <p
                ref={paragraphRef}
                className="mt-6 mb-10 text-base text-justify leading-7 text-gray-800 "
              >
                Welcome to our innovative web application designed to streamline your invoicing process! With our platform, you can easily create professional invoices, customize them with your branding, and download them as PDF files for easy sharing and record-keeping.
                <br />
                <br />
                Upon signing up, you'll have access to a user-friendly interface where you can input all the necessary details for your invoices, including client information, itemized services or products, pricing, and more. Our system ensures accuracy and efficiency, allowing you to generate invoices quickly and effortlessly.
                <br />
                <br />
                Whether you're a freelancer, small business owner, or managing invoices for a larger organization, our platform is tailored to meet your invoicing needs. Say goodbye to manual invoicing hassles and hello to a seamless invoicing experience with our web application. Sign up today to start creating professional invoices with ease!
                  <>
                    <br />
                  </>
              </p>
            </div>
          </div>
          <div className="pb-3" style={{ display: "flex", justifyContent: "center" }}>
          <Link to="/user-login"><button
                      className="mt-10 text-white font-semibold rounded bg-transparent border px-10 py-4 hover:text-white transition-colors duration-300"
                      style={{
                        borderRadius: "8px",
                        backgroundImage:
                          "linear-gradient(to right, #0000FF, #FF0000)",
                        backgroundSize: "200% auto",
                        transition: "background-position 0.5s",
                        fontSize: "1.6rem", // Adjust the font size as needed
                        textAlign: "center", // Center the text horizontally
                        width: "fit-content", // Make the button width fit its content
                        fontWeight: "700"
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundPosition = "right")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundPosition = "left")
                      }
                    >
                    Sign In
                    </button></Link> 
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
