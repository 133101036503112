import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllProductsShop, deleteProduct } from "../../redux/actions/product";
import { getAllEventsShop } from "../../redux/actions/event";
import Loader from "../Layout/Loader";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { DataGrid } from "@material-ui/data-grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AllProducts = () => {
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [reloadMessageOpen, setReloadMessageOpen] = useState(false);
  const [rows, setRows] = useState([]); // Define rows state here
  const [totalProductAmount, setTotalProductAmount] = useState(0);
  const [totalDiscountedAmount, setTotalDiscountedAmount] = useState(0);
  const [totalVATAmount, setTotalVATAmount] = useState(0);
  const [totalInvoice, setTotalInvoice] = useState(0);
  const [TotalafterlDiscountedAmount, setTotalafterlDiscountedAmount] = useState(0);
  const { events, isLoading: eventsLoading } = useSelector((state) => state.events);
  const [eventDetails, setEventDetails] = useState(null);

  useEffect(() => {
    dispatch(getAllProductsShop(seller._id));
    dispatch(getAllEventsShop(seller._id));
  }, [dispatch]);

  useEffect(() => {
    if (deleteSuccess) {
      setTimeout(() => {
        setReloadMessageOpen(true);
      }, 500);
    }
  }, [deleteSuccess]);

  const handleDelete = (id) => {
    setProductIdToDelete(id);
    setOpenConfirmationDialog(true);
  };

  const navigate = useNavigate();
  const handleConfirmDelete = () => {
    dispatch(deleteProduct(productIdToDelete));
    setProductIdToDelete(null);
    setOpenConfirmationDialog(false);
    window.location.reload();
    navigate("/dashboard-invoices");
    setDeleteSuccess(true);
  };

  const handleCancelDelete = () => {
    setProductIdToDelete(null);
    setOpenConfirmationDialog(false);
  };

  const handleCloseReloadMessage = () => {
    setReloadMessageOpen(false);
  };

  const handleEdit = (id) => {};

  const columns = [
    { field: "id", headerName: "Id", width: 100 },
    { field: "inviceNo", headerName: "Invoice#", width: 150 },
    { field: "date", headerName: "Invoice Date", width: 160 },
    { field: "due", headerName: "Due Date", width: 140 },
    { field: "party", headerName: "Name of Party", width: 200 },
    { field: "amount", headerName: "Amount", width: 140 },
    { field: "discount", headerName: "Discount", width: 140 },
    { field: "taxableAmt", headerName: "Taxable Amount", width: 190 },
    { field: "vat", headerName: "VAT", width: 130 },
    { field: "invoice", headerName: "Invoice Amount", width: 190 },
    {
      field: "Preview",
      minWidth: 110,
      type: "text",
      sortable: false,
      renderCell: (params) => (
        <Link to={`/invoice/${params.id}`}>
          <Button>
            <AiOutlineEye size={20} />
          </Button>
        </Link>
      ),
    },
    {
      field: "Edit",
      minWidth: 100,
      type: "text",
      sortable: false,
      renderCell: (params) => (
        // Inside the renderCell method for the Edit button
        <Link
          to={`/update-invoice/${params.id}`}
        >
          <Button>
            <AiOutlineEdit size={20} />
          </Button>
        </Link>
      ),
    },
    {
      field: "Delete",
      minWidth: 100,
      type: "text",
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => handleDelete(params.id)}>
          <AiOutlineDelete size={20} />
        </Button>
      ),
    },
  ];

  
  useEffect(() => {
    // Clone the allProducts array to avoid mutating the original array
    const clonedProducts = allProducts.slice();
    
    // Filter products based on seller._id and shopId
    const filteredProducts = clonedProducts.filter(
      (item) => item.shopId === seller._id
    );
  
    // Sort the cloned array based on invoiceNumber in descending order
    filteredProducts.sort((a, b) => b.invoiceNumber - a.invoiceNumber);
  
    const rows = filteredProducts.map((item) => ({
      id: item._id,
      inviceNo: `${seller.description}00000${item.invoiceNumber}`,
      date: new Date(item.startDate).toLocaleDateString(),
      due: new Date(item.endDate).toLocaleDateString(),
      party: item.selectedParty,
      amount: item.totalProductAmount,
      discount: item.totalDiscountedAmount,
      taxableAmt: item.TotalafterlDiscountedAmount,
      vat: item.totalVATAmount,
      invoice: item.totalInvoice
    }));
  
    // Fetch event details for each selected party
    const fetchEventDetails = async () => {
      const eventDetailsArray = await Promise.all(
        rows.map(async (row) => {
          if (row.party) {
            const selectedEvent = events.find((event) => event._id === row.party);
            return { ...row, party: selectedEvent ? selectedEvent.name : '' };
          }
          return row;
        })
      );
      setRows(eventDetailsArray);
    };
  
    fetchEventDetails();
  
    // Calculate the total invoice amount
    const totalProductAmount = rows.reduce((total, row) => total + row.amount, 0);
    setTotalProductAmount(totalProductAmount);
    const totalDiscountedAmount = rows.reduce((total, row) => total + row.discount, 0);
    setTotalDiscountedAmount(totalDiscountedAmount);
    const TotalafterlDiscountedAmount = rows.reduce((total, row) => total + row.taxableAmt, 0);
    setTotalafterlDiscountedAmount(TotalafterlDiscountedAmount);
    const totalVATAmount = rows.reduce((total, row) => total + row.vat, 0);
    setTotalVATAmount(totalVATAmount);
    const totalInvoice = rows.reduce((total, row) => total + row.invoice, 0);
    setTotalInvoice(totalInvoice);
  }, [allProducts, seller, events]);
  

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            autoWidth
          />
          <div className="flex mt-4 justify-center py-2 px-4">
            <div className="border-2 p-2 w-[100%] md:w-[50%] border-black border-solid">
            <div className="400px:flex justify-evenly p-2">
              <strong className="w-[200px]">Total Product Amount: </strong>
              {seller.currency}{totalProductAmount.toFixed(2)}
            </div>
            <div className="400px:flex justify-evenly p-2">
              <strong className="w-[200px]" >Total Discounted Amount: </strong>
              {seller.currency}{totalDiscountedAmount.toFixed(2)}
            </div>
            <div className="400px:flex justify-evenly p-2">
              <strong className="w-[200px]">Total Taxable Amount: </strong>
              {seller.currency}{TotalafterlDiscountedAmount.toFixed(2)}
            </div>
            <div className="400px:flex justify-evenly p-2">
              <strong className="w-[200px]">Total VAT Amount: </strong>
              {seller.currency}{totalVATAmount.toFixed(2)}
            </div>
            <div className="400px:flex justify-evenly p-2">
              <strong className="w-[200px]">Total Invoice Amount: </strong>
              {seller.currency}{totalInvoice.toFixed(2)}
            </div>
            </div>
          </div>
          <Dialog
            open={openConfirmationDialog}
            onClose={handleCancelDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this product?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelDelete} color="primary">
                No
              </Button>
              <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={reloadMessageOpen}
            autoHideDuration={4000}
            onClose={handleCloseReloadMessage}
          >
            <MuiAlert
              onClose={handleCloseReloadMessage}
              severity="success"
              elevation={6}
              variant="filled"
            >
              Invoice has been successfully deleted. Reloading...
              {/* Navigate to dashboard page */}
              <Link to="/dashboard-invoices" style={{ textDecoration: "none" }}>
                <Button variant="outlined" color="inherit">
                  View all Invoices
                </Button>
              </Link>
            </MuiAlert>
          </Snackbar>
        </div>
      )}
    </>
  );
};

export default AllProducts;
