import React from "react";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Footer from "../components/Layout/Footer";
import Whatsapp from "../components/Whatsapp/whatsapp";
const HomePage = () => {
  return (
    <div>
      <div className="mt-5"></div>
      <FeaturedProduct/>
      <Whatsapp />
      <Footer />
    </div>
  );
};

export default HomePage;
