import React, { useEffect, useState, useRef } from "react";
import styles from "../../styles/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductsShop } from "../../redux/actions/product";
import { getAllEventsShop } from "../../redux/actions/event";
import { backend_url } from "../../server";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { saveAs } from 'file-saver';
import { Document, Page, Text, View, pdf, Image, Font} from "@react-pdf/renderer";
import { createTw } from "react-pdf-tailwind";
import  logo  from './logo.png';
import './OrderDetails.css'
import OpenSansExtraBold from '../../Roboto/Roboto-Bold.ttf';
import OpenSansNormal from '../../Roboto/Roboto-Regular.ttf';
import OpenSanslight from '../../Roboto/Roboto-Light.ttf';
import axios from "axios";  
import { ToWords } from 'to-words';

Font.register({
  family: 'Roboto', fonts: [
    { src: OpenSansNormal, fontWeight: 'normal' },
    { src: OpenSansExtraBold, fontWeight: 'bold' },
    { src: OpenSanslight, fontWeight: 'light' },
    // Add other font weights and styles as needed
  ],
});

// The 'theme' object is your Tailwind theme config
const tw = createTw({
  
  theme: {
    fontFamily: {   
    },
    extend: {
      colors: {  
      },
    },
  },
});

const InvoiceDetails = () => {
  const { allProducts, isLoading: productsLoading } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);
  const { events, isLoading: eventsLoading } = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [eventDetails, setEventDetails] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");
  const [selectedParty, setselectedParty] = useState("");
  const imageRef = useRef(null);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [currencyOptionsName, setCurrencyOptionsName] = useState('');
  const [currencyOptionsPlural, setCurrencyOptionsPlural] = useState('');
  const [fractionalUnitName, setFractionalUnitName] = useState('');
  const [fractionalUnitPlural, setFractionalUnitPlural] = useState('');

  useEffect(() => {
    dispatch(getAllProductsShop(seller._id));
    dispatch(getAllEventsShop(seller._id));
  }, [dispatch, seller._id]);

  useEffect(() => {
    if (!productsLoading && !eventsLoading && allProducts && events && id) {
      // Filter products based on seller._id and shopId
      const clonedProducts = allProducts.slice();
      const filteredProducts = clonedProducts.filter(
        (item) => item.shopId === seller._id
      );
  
      const data = filteredProducts.find((item) => item._id === id);
      const selectedParty = data?.selectedParty;
      if (selectedParty) {
        const selectedEvent = events.find((event) => event._id === selectedParty);
        setEventDetails(selectedEvent);
        setData(data);
        setselectedParty(selectedEvent.name);
      }
      setLoading(false); // Data loaded, update loading state
    }
  }, [productsLoading, eventsLoading, allProducts, events, id, seller]);

  useEffect(() => {
    document.title = `#${seller.description}00000${data?.invoiceNumber}`;
  }, [seller.description, data?.invoiceNumber]);
  

  const selectedValue = seller.currencycode
  useEffect(() => {
    if (selectedValue === 'en-US') {
      setCurrencyOptionsName('Dollar');
      setCurrencyOptionsPlural('Dollars');
      setFractionalUnitName('Cent');
      setFractionalUnitPlural('Cents');
    } else if (selectedValue === 'en-GB') {
      setCurrencyOptionsName('Pound');
      setCurrencyOptionsPlural('Pounds');
      setFractionalUnitName('Pence');
      setFractionalUnitPlural('Pence');
    } else if (selectedValue === 'en-AE') {
      setCurrencyOptionsName('Dirham');
      setCurrencyOptionsPlural('Dirhams');
      setFractionalUnitName('Fils');
      setFractionalUnitPlural('Fils');
    } else if (selectedValue === 'en-NP') {
      setCurrencyOptionsName('Rupee');
      setCurrencyOptionsPlural('Rupees');
      setFractionalUnitName('Paisa');
      setFractionalUnitPlural('Paisa');
    } else if (selectedValue === 'en-IN') {
      setCurrencyOptionsName('Rupee');
      setCurrencyOptionsPlural('Rupees');
      setFractionalUnitName('Paise');
      setFractionalUnitPlural('Paise');
    }
  }, [selectedValue]);
  
  
  const toWords = new ToWords({
  localeCode: selectedValue,
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: currencyOptionsName,
      plural: currencyOptionsPlural,
      fractionalUnit: {
        name: fractionalUnitName,
        plural: fractionalUnitPlural,
      },
    },
  },
});

  
  const logourl = `${backend_url}${seller.avatar?.url}`

  useEffect(() => {
    const img = new window.Image();
    img.src = logourl;
    img.onload = () => {
      setHeight(img.naturalHeight);
      setWidth(img.naturalWidth);
      setLoading(false);
    };
    img.onerror = (error) => {
      console.error('Error loading image:', error);
      setLoading(false);
    };
  }, [logourl]);

  
  if (loading || !data || !selectedParty || !eventDetails) {
    return <div>Loading...</div>;
  }

  const handlePrint = () => {
    window.print();
  };
  
const MyPDFDocument = () => (
  <Document>
  <Page size="A4" style={{ 
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    padding: 25,
      }}>
  <View>
      <View
        style={tw("flex-row justify-between")}
      >
        <View>
          <Image
            src={logourl}
            style={{ maxHeight: 70, maxWidth: (70 / height) * width  }}
          />
          <Text style={tw("text-[16px] py-1 font-bold")}>{seller.name}</Text>
          <Text style={tw("text-[13px] pb-1")}>{seller.address}</Text>
          <Text style={tw("text-[13px] pb-1")}>TRN: {seller.phoneNumber}</Text>
          <Text style={tw("text-[13px] pt-5")}>
            Bill To:
          </Text>
          <Text style={tw("text-[16px] pb-1 font-bold")}>
            {selectedParty}
          </Text>
          {eventDetails && (
            <View>
              <Text style={tw("text-[13px] pb-1")}>{eventDetails.description}</Text>
              <Text style={tw("text-[13px] pb-1")}>TRN: {eventDetails.category}</Text>
              {/* Add other details here */}
            </View>
          )}
        </View>
        <View>
        <View style={tw("text-[18px] flex-row justify-end pb-1 font-bold")}><Text>
        TAX INVOICE</Text></View>
        <View style={tw("text-[13px] flex-row justify-end font-light pb-3")} ><Text>
            #{seller.description}00000{data?.invoiceNumber}
          </Text></View>
          <View style={tw("text-[13px] flex-row justify-end font-light pb-1")} ><Text>
            Invoice Date: {data?.startDate?.slice(0, 10)}
          </Text></View>
          <View style={tw("text-[13px] flex-row justify-end font-light pb-3")} ><Text>
            Due Date: {data?.endDate?.slice(0, 10)}
          </Text></View>
          <View style={tw("text-[13px] flex-row justify-end font-bold")}><Text>
            Balance Due: {seller.currency}
            {data?.totalInvoice !== undefined &&
            data.totalInvoice !== null
              ? data.totalInvoice.toFixed(2)
              : ""}
          </Text></View>
        </View>
      </View>
      {/* Item details table */}
      <View style={{ marginTop: 10 }}>
        <View
          style={tw("flex-row items-center border-x border-t border-gray-400")}
        >
          <Text style={tw("leading-5 font-bold text-[12px] px-2 py-2 flex-[4.5]")}>Description</Text>
          <Text style={tw("text-center leading-5 font-bold text-[12px] px-2 py-2 flex-[3.7]")}>Qty.</Text>
          <Text style={tw("text-center leading-5 font-bold text-[12px] px-2 py-2 flex-[3.5]")}>Amount</Text>
          <Text style={tw("text-center leading-5 font-bold text-[12px] px-2 py-2 flex-[1.5]")}>Discount</Text>
          <Text style={tw("text-center leading-5 font-bold text-[12px] px-2 py-2 flex-[3.5]")}>Taxable Amount</Text>
          <Text style={tw("text-center leading-5 font-bold text-[12px] px-2 py-2 flex-[1.3]")}>VAT</Text>
          <Text style={tw("text-right leading-5 font-bold  text-[12px] px-2 py-2 flex-[3.7]")}>Invoice Amount</Text>
        </View>
        {/* Item rows */}
        {data?.itemsList.map((item, index) => (
          <View
            key={index}
            style={tw("flex-row items-center border-x border-t border-gray-400")}
          >
            <Text style={tw("leading-5 text-[11px] px-2 py-2 flex-[4.5]")}>{item.description}</Text>
            <Text style={tw("leading-5 text-center text-[11px] px-2 py-2 flex-[3.7]")}>{item.stock} {item.unit} @ {(item.unitprice).toFixed(2)}</Text>
            <Text style={tw("leading-5 text-center text-[11px] px-2 py-2 flex-[3.5]")}>{(item.productamount).toFixed(2)}</Text>
            <Text style={tw("leading-5 text-center text-[11px] px-2 py-2 flex-[1.5]")}>{item.discount}%</Text>
            <Text style={tw("leading-5 text-center text-[11px] px-2 py-2 flex-[3.5]")}>{(item.discountPrice).toFixed(2)}</Text>
            <Text style={tw("leading-5 text-center text-[11px] px-2 py-2 flex-[1.3]")}>{item.vatrate}%</Text>
            <Text style={tw("leading-5 text-right  text-[11px] px-2 py-2 flex-[3.7]")}>{(item.amtaftervat).toFixed(2)}</Text>
          </View>
        ))}
        {/* Total row */}
        <View
         style={tw("flex-row items-center border-x border-y border-gray-400")}
        >
          <Text style={tw("leading-5 text-[11px] font-[600] px-2 py-2 flex-[4.4]")}>Total:</Text>
          <Text style={tw("text-center leading-5 text-[11px] font-[600] px-2 py-2 flex-[3.7]")}></Text>
          <Text style={tw("text-center leading-5 text-[11px] font-[600] px-2 py-2 flex-[3.5]")}>{data?.totalProductAmount.toFixed(2)}</Text>
          <Text style={tw("text-center leading-5 text-[11px] font-[600] px-2 py-2 flex-[1.5]")}></Text>
          <Text style={tw("text-center leading-5 text-[11px] font-[600] px-2 py-2 flex-[3.5]")}>{data?.TotalafterlDiscountedAmount.toFixed(2)}</Text>
          <Text style={tw("text-center leading-5 text-[11px] font-[600] px-2 py-2 flex-[1.3]")}></Text>
          <Text style={tw("text-right leading-5  text-[11px] font-[600] px-2 py-2 flex-[3.8]")}>{data?.totalInvoice.toFixed(2)}</Text>
        </View>
      </View>

      {/*Bank details*/}
      <View style={{ marginTop: 20 }}>
        <View
          style={tw("flex-row justify-between")}>
          {/* Bank Details */}
          <View style={tw("w-[60%]")}>
            <View style={{ marginBottom: 10 }}>
                {seller.bankDetails && ( // Check if seller.bankDetails exists
                  <Text style={tw("text-[13px] font-bold pb-2")}>
                    Bank Details:
                  </Text>
                )}
              {seller.bankDetails &&
                seller.bankDetails.split("\n").map((line, index) => (
                  <Text key={index} style={tw("leading-6 font-light text-[12px]")}>
                    {line}
                  </Text>
                ))}
            </View>
            <View style={{ marginBottom: 10 }}>
            {data?.term && ( // Check if seller.bankDetails exists
              <Text style={tw("text-[13px] font-bold pb-2")}>
                Terms and Conditions:
              </Text>
                )}
              <View style={{ width: "90%", textAlign: "justify" }}>
                {data?.term &&
                  data.term.split("\n").map((line, index) => (
                    <Text
                      key={index}
                      style={tw("leading-6 font-light text-[12px]")}
                    >
                      •{" "}{line}
                    </Text>
                  ))}
              </View>
            </View>
            <View style={{ marginBottom: 10 }}>
              <Text style={tw("text-[13px] font-bold pb-2")}>
                Amount In Word:
              </Text>
              <View style={{ width: "90%", textAlign: "justify" }}>
                    <Text
                      style={tw("leading-6 font-light text-[12px]")}
                    >
                      {words}.
                    </Text>
              </View>
            </View>
          </View>
          {/* Total amounts */}
          <View style={tw("w-[40%]")}>
          <View style={tw("pb-3 flex-row justify-between")}>
             <View> 
              <Text style={tw("text-[13px] font-bold")}>
                Total Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </Text></View>
                <View><Text style={tw("text-[13px] font-bold")}>
                  {seller.currency}
                  {data?.totalProductAmount !== undefined
                    ? data.totalProductAmount.toFixed(2)
                    : ""}
                </Text></View>
            </View>
            <View style={tw("pb-3 flex-row justify-between")}>
             <View> 
              <Text style={tw("text-[13px] font-bold")}>
                Total Discount&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </Text></View>
                <View><Text style={tw("text-[13px] flex-row font-bold")}>
                  {seller.currency}
                  {data?.totalDiscountedAmount !== undefined
                    ? data.totalDiscountedAmount.toFixed(2)
                    : ""}
                </Text></View>
            </View>
            <View style={tw("pb-3 flex-row justify-between")}>
             <View> 
              <Text style={tw("text-[13px] font-bold")}>
                Taxable Amount&nbsp;{" "}
                </Text></View>
                <View><Text style={tw("text-[13px] font-bold")}>
                  {seller.currency}
                  {data?.TotalafterlDiscountedAmount !== undefined
                    ? data.TotalafterlDiscountedAmount.toFixed(2)
                    : ""}
                </Text></View>
            </View>
            <View style={tw("pb-3 flex-row justify-between")}>
             <View> 
              <Text style={tw("text-[13px] font-bold")}>
                VAT (If Any)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </Text></View>
                <View><Text style={tw("text-[13px] font-bold")}>
                  {seller.currency}
                  {data?.totalVATAmount !== undefined
                    ? data.totalVATAmount.toFixed(2)
                    : ""}
                </Text></View>
            </View>
            <View style={tw("pb-3 flex-row justify-between")}>
             <View> 
              <Text style={tw("text-[13px] font-bold")}>
                Invoice Amount&nbsp;&nbsp;{" "}
                </Text></View>
                <View><Text style={tw("text-[13px] font-bold")}>
                  {seller.currency}
                  {data?.totalInvoice !== undefined
                    ? data.totalInvoice.toFixed(2)
                    : ""}
                </Text></View>
            </View>
          </View>
        </View>
      </View>

      {/* Notes */}
      <View style={{ marginTop: 5 }}>
        {seller.note && (
          <>
            <Text
              style={tw("text-[13px] font-bold pb-2")}
            >
              Notes:
            </Text>
            <View style={{ textAlign: "justify" }}>
              {seller.note.split("\n").map((line, index) => (
                <Text key={index} style={tw("leading-6 pb-4 text-[12px]")}>
                  {line}
                </Text>
              ))}
            </View>
          </>
        )}
      </View>
    </View>
    {/* End message */}
    <View style={{ position: 'absolute', bottom: 20, left: 0, right: 0, textAlign: 'center' }}>
        <Text
          style={tw("text-[#00000095] leading-5 text-[13px]")}
        >
          Thank you for your business!
        </Text>
      </View>
  </Page>
</Document>
);


const words = toWords.convert(data?.totalInvoice.toFixed(2));

const downloadPDF = async () => {
      const blob = await pdf(<MyPDFDocument />).toBlob();
      saveAs(blob, "Invoice" + "#" + seller.description + "00000" + data?.invoiceNumber + ".pdf");
    };
  
  return (
    <div className={`py-4 min-h-[100px] w-[90%] md:w-[70%] justify-center ${styles.section} bg-white font-[sans-serif]`}>
    <div id="printinvoice" className={`min-h-screen w-[100%] justify-between ${styles.section} bg-white`}>
    <div className="flex justify-between">
    <div>
    <img ref={imageRef} 
          src={logourl}
          alt=""
          className="w-[auto] max-h-[100px]"
    />
      <p className="text-[25px] mt-2 font-[600]">{seller.name}</p>
      <p  className="text-[18px]">{seller.address}</p>
      <p className="text-[18px]">TRN: {seller.phoneNumber}</p>
      <h5 className="text-[#00000084] text-left pt-5">Bill To:</h5>
      <p className="text-left text-[20px] font-[600]">{selectedParty}</p>
      <div>
      {eventDetails && (
        <div className="text-left text-[16px]">
          <p>Address: {eventDetails.description}</p>
          <p>TRN: {eventDetails.category}</p>
          {/* Add other details here */}
        </div>
      )}
      </div>
    </div>
    <div>
      <p className="text-right font-[600] text-[28px]">TAX INVOICE</p>
      <h5 className="text-[#00000090] text-right">
          <span>#{seller.description}00000{data?.invoiceNumber}</span>
        </h5>  
        <h5 className="text-[#00000090] pt-3 text-right">
          Invoice Date: <span>{data?.startDate?.slice(0, 10)}</span>
        </h5>
        <h5 className="text-[#00000090] text-right">
          Due Date: <span>{data?.endDate?.slice(0, 10)}</span>
        </h5>
        <div className="text-right font-[600] text-[18px] my-2">Balance Due: {seller.currency}{(data?.totalInvoice).toFixed(2)}</div>
    </div>
    </div>
        {/* Item details table */}
      <div className="mt-5">
        <table className="w-full border-collapse border border-gray-400 mt-3">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-400 px-4 text-center py-2 min-w-[240px]">Description</th>
              <th className="border border-gray-400 px-4 text-center py-2">Qty.</th>
              <th className="border border-gray-400 px-4 text-center py-2">Amount</th>
              <th className="border border-gray-400 px-4 text-center py-2">Discount (%)</th>
              <th className="border border-gray-400 px-4 text-center py-2">Taxable Amount</th>
              <th className="border border-gray-400 px-4 text-center py-2">VAT (%)</th>
              <th className="border border-gray-400 px-4 text-center py-2">Invoice Amount</th>
            </tr>
          </thead>
          <tbody>
            {/* Map over items and render rows */}
            {data && data.itemsList.map((item, index) => (
              <tr key={index}>
                <td className="border border-gray-400 px-4 py-2">{item.description}</td>
                <td className="border border-gray-400 px-4 text-center py-2">{item.stock} {item.unit} @ {(item.unitprice).toFixed(2)}</td>
                <td className="border border-gray-400 px-4 text-center py-2">{(item.productamount).toFixed(2)}</td>
                <td className="border border-gray-400 px-4 text-center py-2">{item.discount}%</td> 
                <td className="border border-gray-400 px-4 text-center py-2">{(item.discountPrice).toFixed(2)}</td>
                <td className="border border-gray-400 px-4 text-center py-2">{item.vatrate}%</td>
                <td className="border border-gray-400 px-4 text-center py-2">{(item.amtaftervat).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
          {/* Total row */}
          <tfoot>
            <tr className="bg-gray-100">
              <td colSpan="2" className="border border-gray-400 px-4 py-2 text-center font-semibold">Total:</td>
              <td className="border border-gray-400 px-4 py-2 text-center font-semibold">{data?.totalProductAmount.toFixed(2)}</td>
              <td></td>
              <td className="border border-gray-400 px-4 py-2 text-center font-semibold">{data?.TotalafterlDiscountedAmount.toFixed(2)}</td>
              <td></td>
              <td className="border border-gray-400 px-4 py-2 text-center font-semibold">{data?.totalInvoice.toFixed(2)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
            

      <div className="flex justify-between">
    <div className="w-[60%]">
    <div>
    {seller.bankDetails && (
  <>
    <p className="text-[16px] 800px:text-[16px] mt-5 pb-1 font-[600]">Bank Details:</p>
    <div className="w-[90%]">
    {seller.bankDetails.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        <span></span><span className="text-[#00000095]">{line}</span>
        <br />
      </React.Fragment>
    ))}</div>
  </>
)}

    </div>
    <div>
    {data?.term && (
  <>
    <p className="text-[16px] 800px:text-[16px] mt-5 pb-1 font-[600]">Terms and Conditions:</p>
    <div className="w-[90%] text-justify">
    {data?.term.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        <span className="text-[18px] text-justify text-[#00000095]">• </span><span className="text-[14px] text-justify text-[#00000095]">{line}</span>
        <br />
      </React.Fragment>
    ))}</div>
  </>
)}
    </div>
    <div>
    <p className="text-[16px] 800px:text-[16px] pb-1 mt-5 font-[600]">Amount In Word:</p>
    <div className="w-[90%] text-justify">
        <span className="text-[14px] text-justify text-[#00000095]">{words}.</span>
    </div>
    </div>
    </div>
    <div className="w-[40%]">
        <div className="font-[600] text-[18px] mt-5 min-w-[300px]">
              <div className="flex my-2 justify-between"><p>Total Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p><span> {seller.currency}{(data?.totalProductAmount).toFixed(2)}</span></div>
              <div className="flex my-2 justify-between"><p>Total Discount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p><span> {seller.currency}{(data?.totalDiscountedAmount).toFixed(2)}</span></div>
              <div className="flex my-2 justify-between"><p>Taxable Amount&nbsp;&nbsp;</p><span> {seller.currency}{(data?.TotalafterlDiscountedAmount).toFixed(2)}</span></div>
              <div className="flex my-2 justify-between"><p>VAT (If Any)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p><span> {seller.currency}{(data?.totalVATAmount).toFixed(2)}</span></div>
              <div className="flex my-2 justify-between"><p>Invoice Amount&nbsp;&nbsp;&nbsp;</p><span> {seller.currency}{(data?.totalInvoice).toFixed(2)}</span></div>
        </div>
    </div>
    </div>
    <div>
    {seller.note && (
  <>
    <p className="text-[16px] 800px:text-[16px] pb-1 font-[600] mt-[20px]">Notes:</p>
    <div className="text-justify">
    {seller.note.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        <span></span><span className=" text-[14px]">{line}</span>
        <br />
      </React.Fragment>
    ))}</div>
  </>
)}
    </div>
    <div id="endDiv" className="flex mt-[20px] justify-center">
    <h5 className="text-[#00000084] font-[500] text-[18px] text-center">Thank you for your business!</h5>
    </div>
    </div>
     {/* Print button */}
     {/* <div className="flex justify-center mt-10 text-[22px] font-[600] mb-5">
     <button className="bg-green-700 p-3 rounded-lg text-white" onClick={handlePrint}>Print</button></div> */}
     
     <div className="flex justify-center mt-5 text-[22px] font-[600] mb-5">
     <button className="bg-blue-500 p-3 rounded-lg text-white" onClick={downloadPDF}>Download PDF</button></div>
     <div className="flex justify-center mt-5 text-[18px] font-[600] mb-5">
     {/* <Link to={`${data.images && data.images[0]?.url}`} target="_blank">
     <button className="bg-gray-500 p-3 rounded-lg text-white">Supporting Document (If Any)</button>
     </Link> */}
     </div>
     {/* Style for print */}
        <style>
          {`
            @media print {
              body {
                visibility: hidden;
              }
              #printinvoice {
                visibility: visible;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
              }
              #endDiv {
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
          }
          /* Set paper size to A4 */
          @page {
            size: A4;
          }      
        }
          `}
        </style>
    </div>
  );
};

export default InvoiceDetails;
