import React, { useEffect } from 'react';
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader'
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar'
import UpdateCustomer from '../../components/Shop/UpdateCustomer';

const ShopUpdateCustomer = () => {

  useEffect(() => {
    document.title = 'Update Customer - Conscious Invoice';
  }, []);
  
    return (
        <div>
            <DashboardHeader />
            <div className="flex justify-between w-full">
                <div className="w-[80px] 800px:w-[330px]">
                  <DashboardSideBar active={4} />
                </div>
                <div className="w-full mt-5 justify-center flex">
                    <UpdateCustomer />
                </div>
              </div>
        </div>
      )
    }

export default ShopUpdateCustomer