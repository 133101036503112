import axios from "axios";
import { server } from "../../server";

export const getAllLicencedetail = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAlllicencedetailRequest",
    });

    const { data } = await axios.get(`${server}/licensedetail/get-all-licencedetails`);
    dispatch({
      type: "getAlllicencedetailSuccess",
      payload: data.LicenseDetails,
    });
  } catch (error) {
    dispatch({
      type: "getAlllicencedetailFailed",
      payload: error.response.data.message,
    });
  }
};

export const getLicenseDetailsByEmail = (email) => async (dispatch) => {
  try {
    dispatch({
      type: "getLicenseDetailsRequest",
    });

    const { data } = await axios.get(`${server}/licensedetail/get-license-by-email/${email}`);
    dispatch({
      type: "getLicenseDetailsSuccess",
      payload: data.licenseDetail,
    });
  } catch (error) {
    dispatch({
      type: "getLicenseDetailsFailed",
      payload: error.response.data.message,
    });
  }
};