import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllEventsShop, deleteEvent } from "../../redux/actions/event";
import { getAllProductsShop} from "../../redux/actions/product";
import Loader from "../Layout/Loader";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AllEvents = () => {
  const { events, isLoading } = useSelector((state) => state.events);
  const { seller } = useSelector((state) => state.seller);
  const { allProducts } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const [eventIdToDelete, setEventIdToDelete] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [reloadMessageOpen, setReloadMessageOpen] = useState(false);
  useEffect(() => {
    dispatch(getAllEventsShop(seller._id));
    dispatch(getAllProductsShop(seller._id));
  }, [dispatch]);

  useEffect(() => {
    if (deleteSuccess) {
      setTimeout(() => {
        setReloadMessageOpen(true);
      }, 500);
    }
  }, [deleteSuccess]);

  const handleDelete = (id) => {
    setEventIdToDelete(id);
    setOpenConfirmationDialog(true);
  };

  const navigate = useNavigate();
  const handleConfirmDelete = () => {
    const isEventInUse = allProducts.some(product => product.selectedParty === eventIdToDelete);
    if (isEventInUse) {
      setEventIdToDelete(null);
      setOpenConfirmationDialog(false);
      toast.error("This Customer is currently use for invoice and cannot be deleted.");
      return;
    }  
    dispatch(deleteEvent(eventIdToDelete));
    setEventIdToDelete(null);
    setOpenConfirmationDialog(false);
    setDeleteSuccess(true);
    window.location.reload();
    navigate("/dashboard-customers");
  };

  const handleCancelDelete = () => {
    setEventIdToDelete(null);
    setOpenConfirmationDialog(false);
  };

  const handleCloseReloadMessage = () => {
    setReloadMessageOpen(false);
  };

  const columns = [
    { field: "id", headerName: "Id", minWidth: 80},
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
    },
    {
      field: "description",
      headerName: "Address",
      minWidth: 200,
    },
    {
      field: "category",
      headerName: "TRN",
      minWidth: 150,
    },

    {
      field: "tags",
      headerName: "Contact Person",
      minWidth: 200,
    },

    {
      field: "originalPrice",
      headerName: "Contact No.",
      minWidth: 170,
    },
    {
      field: "Edit",
      minWidth: 100,
      type: "text",
      sortable: false,
      renderCell: (params) => (
        // Inside the renderCell method for the Edit button
        <Link
          to={`/update-customers/${params.id}`}
        >
          <Button>
            <AiOutlineEdit size={20} />
          </Button>
        </Link>
      ),
    },
    {
      field: "Delete",
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];

  events &&
    events.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        description: item.description,
        category: item.category,
        tags: item.tags,
        originalPrice: item.originalPrice
      });
    });

    row.sort((a, b) => b.id.localeCompare(a.id));

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            autoWidth
          />
          <Dialog
            open={openConfirmationDialog}
            onClose={handleCancelDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete Customer?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelDelete} color="primary">
                No
              </Button>
              <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={reloadMessageOpen}
            autoHideDuration={4000}
            onClose={handleCloseReloadMessage}
          >
            <MuiAlert
              onClose={handleCloseReloadMessage}
              severity="success"
              elevation={6}
              variant="filled"
            >
              Event has been successfully deleted. Reloading...
              {/* Navigate to dashboard page */}
              <Link to="/dashboard-customers" style={{ textDecoration: "none" }}>
                <Button variant="outlined" color="inherit">
                  Go to All Customers
                </Button>
              </Link>
            </MuiAlert>
          </Snackbar>
        </div>
      )}
    </>
  );
};

export default AllEvents;
